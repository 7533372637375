import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { apiURLs } from "./config";
import { AdminAdd } from "./AdminAdd";
import logo from "./urologiq.png";

export const AdminAddTable = () => {
  // Declare state variable for selected values
  const [names, setNames] = useState([]);
  const [admin, setAdmin] = useState([]);

  useEffect(() => {
    let isadmin = localStorage.getItem("admin");
    setAdmin(isadmin);
  }, []);

  useEffect(() => {
    let token = localStorage.getItem(`token-aarthi`);
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiURLs.aarthi}/getusers`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setNames(
          response.data.admin_users.sort((a, b) => {
            let fa = a.username.toLowerCase(),
              fb = b.username.toLowerCase();

            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          })
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  if (!admin) {
    const pathname = "/admin/login";
    // navigate with props
    return <Navigate to={{ pathname: pathname }} />;
  }
  return (
    <TableContainer component={Paper}>
      <TableRow>
        <TableCell sx={{ fontWeight: 700, fontSize: "20px", border: "none" }}>
          <img src={logo} style={{ width: "100px" }} />
        </TableCell>
        <TableCell
          sx={{
            width: "25%",
            fontWeight: 700,
            fontSize: "20px",
            border: "none",
          }}
        >
          User Management
        </TableCell>
        <TableCell align="right" sx={{ width: "75%" }}>
          <Link
            className="dropActionlnk"
            to="/admin/jobs"
            params={{ admin: true }}
          >
            Back to Case Table
          </Link>{" "}
        </TableCell>
      </TableRow>
      <Table aria-label="User table">
        <TableHead>
          <TableRow sx={{ background: "#1b4b84" }}>
            <TableCell sx={{ fontWeight: 700, color: "#fff" }}>
              User Name
            </TableCell>
            <TableCell sx={{ fontWeight: 700, color: "#fff" }} align="center">
              Centers
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {names.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.username}
              </TableCell>
              <TableCell>
                <AdminAdd user={row} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
