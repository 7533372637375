import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import "./Jobs.css";
// import http from "./http-common";
import axios from "axios";

import Button from '@mui/material/Button';

import UploadIcon from '@mui/icons-material/Upload';

import { inferURLs, apiURLs, ohifURLs } from "./config";
import { CircularProgress, LinearProgress, selectClasses } from "@mui/material";

import {onLogout} from "./Login";

import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import RefreshIcon from '@mui/icons-material/Refresh';

import {Select, MenuItem} from '@mui/material';

import Modal from '@mui/material/Modal';
import Popper from '@mui/material/Popper';

import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';

import TextField from '@mui/material/TextField';

const styles = {

    wrapper: {
        width: '100%',
        height: '100%',
        // margin: '10px',
        // padding: '20px',
        background: '#fff',
        color: 'black',
        overflow: 'hidden',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        background: '#1b4a92',
        height: '60px',
        alignItems: 'center',
        padding: '20px',
        color: '#fff',
    },
    dashboardWrapper: {
        // height: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    dashboardSelect: {
        fontSize: '20px',
        fontWeight: '700',
        color: '#fff',
        fontFamily: 'Times New Roman',
        height: '40px',
        padding: '0px',
        margin: '0px',
    },
    dashboardHeaderText: {
        fontSize: '20px',
        fontWeight: '700',
        color: '#fff',
        fontFamily: 'Times New Roman',
    },
    logoutWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    logoutButton: {
        color: '#fff',
    },
    content: (mode) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: mode === 'dark'? '#111': '#fff',
        color: mode === 'dark'? '#fff': '#111',
        height: '100vh',
        // width: '100%',
        padding: '20px',
        
    }),
    uploadWrapper: {
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #ccc',
        borderRadius: '5px',
        padding: '20px',
        width: '80%',
        height: '80%',
        maxWidth: '800px',
        maxHeight: '800px',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    select: {
        width: '400px',
        height: '40px',
        fontSize: '20px',
        fontWeight: '700',
        background: '#fff',
        color: '#000',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        height: '80%',
    },
    textField: {
        width: '400px',
        // height: '40px',
        fontSize: '20px',
        background: '#fff',
    },
    selectUpload: mode => ({
        width: '400px',
        height: '200px',
        fontSize: '20px',
        fontWeight: '700',
        background: mode === 'dark'? '#333': '#fff',
        // border: '2px dashed #ccc',
        border: mode === 'dark'? '2px dashed #333': '2px dashed #ccc',
        borderRadius: '10px',
        borderImage: `url(http://i.stack.imgur.com/wLdVc.png) 1 round`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    }),
    uploadButton: {
        width: '400px',
        height: '40px',
        fontSize: '20px',
        fontWeight: '700',
        background: '#049c77',
        color: '#fff',
        borderRadius: '5px',
    },
    jobQueuedModal: {
        width: '400px',
        height: '400px',
        backgroundColor: '#eee',
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        paddingTop: '0px',
        paddingLeft: '20px',
        justifyContent: 'space-between',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        border: '1px solid #002233',
        bordeRadius: '5px'
    },
    popper: {
        background: 'white',
        borderRadius: '5px',
        padding: '10px',
    },
    patientDetails: (mode) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        width: '100%',
        // height: '100%',
        background: mode === 'dark'? '#111': '#fff',
        color: mode === 'dark'? '#fff': '#111',
    }),
    patientDetail: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '80%',
    },
    sequenceDetails: (mode) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        width: '100%',
        // height: '100%',
        background: mode === 'dark'? '#111': '#fff',
        color: mode === 'dark'? '#fff': '#111',
    }),
    sequenceDetail: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '80%',
    },
    viewModal: {
        width: '650px',
        height: '650px',
        backgroundColor: '#eee',
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        justifyContent: 'space-around',
        alignItems: 'center',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        border: '1px solid #002233',
        bordeRadius: '5px'
    },
    viewButton: {
        width: '400px',
        height: '40px',
        fontSize: '20px',
        fontWeight: '700',
        background: '#1b4a92',
    },
    closeIcon: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        width: '100%',
        height: '20px',
    }
}


// light vs dark mode
const Mode = (props) => {
    React.useEffect(() => {
        console.log(props.mode);
    }, [props.mode])
    if(props.mode === 'light') {
        return (
                <DarkModeIcon 
                    style={{marginRight: '10px', cursor: 'pointer'}}
                    onClick={props.toggleMode}
                />
        );
    }else {
        return (
                <LightModeIcon 
                    style={{marginRight: '10px', cursor: 'pointer'}}
                    onClick={props.toggleMode}
                />
        )
    }
}

const Header = (props) => {
    const centers = Object.keys(apiURLs);
    console.log('admin: ', props.admin);
    return (
        <div style={styles.header}>
            <div style={styles.dashboardWrapper}>
                {eval(props.admin) ? 
                <Select style={styles.dashboardSelect} value={props.center} onChange={(e) => props.handleCenterChange(e.target.value)}>
                    {centers.map((center) => (
                        <MenuItem 
                        value={center}
                        // onClick={() => props.handleCenterChange(center)}
                        style={{background: props.center === center ? '#049c77' : '#fff', color: props.center === center ? '#fff' : '#000'}}
                        >{center.toUpperCase()}</MenuItem>
                    ))
                    }
                </Select> : <p style={styles.dashboardHeaderText}>{props.center.toUpperCase()}</p>
                }

            </div>
            <div style={styles.logoutWrapper}>
                <Mode mode={props.mode} toggleMode={props.toggleMode}/>
                <p style={{marginRight: '10px'}}>{props.user}</p>
                <Button style={styles.logoutButton} onClick={props.logout}>Logout</Button>
            </div>
        </div>
    );
}

export default function Upload(props) {
    
    // get navigate state


    const location = useLocation();
    const locationQuery = new URLSearchParams(location.search);
    const navigate = useNavigate();
    
    const [progress, setProgress] = React.useState(0);
    const [isUploading, setIsUploading] = React.useState(false);
    const [isUploaded, setIsUploaded] = React.useState(false);
    const [isWaiting, setIsWaiting] = React.useState(false);
    const [fileType, setFileType] = React.useState('zip');
    const [filesSelected, setFilesSelected] = React.useState('');
    const [jobId, setJobId] = React.useState('');
    
    const [metadata, setMetadata] = React.useState({});
    const [sequence, setSequence] = React.useState('');
    const [autoSequence, setAutoSequence] = React.useState('');
    const [imgSrc, setImgSrc] = React.useState('');

    const [jobQueuedModal, setJobQueuedModal] = React.useState(false);
    const [viewModal, setViewModal] = React.useState(false);

    const [ toastMessage, setToastMessage ] = useState('');
    const [ toastOpen, setToastOpen ] = useState(false);
    const [ toastAnchorEl, setToastAnchorEl ] = useState(null);

    const [ baseURL, setBaseURL ] = useState('');
    const [ baseURLAPI, setBaseURLAPI ] = useState('');
    const [ ohifURL, setOhifURL ] = useState('');
    const [ center, setCenter ] = useState('');
    const [ admin, setAdmin ] = useState(false);
    const [ mode, setMode ] = useState();

    const [ dataFrom, setDataFrom ] = useState(''); 

    const [ loggedIn, setLoggedIn ] = useState(true);

    const fileRefDcm = React.useRef(null);
    const fileRefZip = React.useRef(null);
    const fileRefRar = React.useRef(null);
    const uploadButtonRef = React.useRef(null);

    const token = admin ? localStorage.getItem(`token-${center}`) : localStorage.getItem('token');

    React.useEffect(() => {
        console.log('getting center and admin');
        // get center from local storage
        const center = localStorage.getItem('center');
        const admin = localStorage.getItem('admin');
        // check if mode is set in local storage
        const mode = localStorage.getItem('mode') || 'dark';
        

        setCenter(center);
        setAdmin(admin === 'true');
        setMode(mode);

        if(!center || !admin){
            setLoggedIn(false);
        }

        console.log(center, admin, 'center and admin');

        setBaseURL(inferURLs[center]);
        setBaseURLAPI(apiURLs[center]);
        setOhifURL(ohifURLs[center]);
    }, []);

   
    
    const toggleMode = () => {
        const mode = localStorage.getItem('mode');
        if(mode === 'light'){
            localStorage.setItem('mode', 'dark');
            setMode('dark');
        }else {
            localStorage.setItem('mode', 'light');
            setMode('light');
        }
        // window.location.reload();
        
    }

    const handleCenterChange = (center) => {
        console.log(center, 'center', inferURLs[center], apiURLs[center]);
        setCenter(center);
        setBaseURL(inferURLs[center]);
        setBaseURLAPI(apiURLs[center]);
        setOhifURL(ohifURLs[center]);
        localStorage.setItem('center', center);
    }

    if(baseURL === ''){
        return(
        // show loading 
        <div style={styles.wrapper}>
            <Header 
                user={localStorage.getItem('user')} 
                mode={mode}
                admin={admin}
                handleCenterChange={handleCenterChange}
                logout={() => onLogout({admin: admin, center: center})}
                toggleMode={toggleMode}
                center={center} 
            />
            <CircularProgress
                style={{color: '#1b4b84', margin: 'auto', marginTop: '100px'}}
            />
        </div>
        );
    }

    if (!loggedIn){
        return <Navigate to="/login" />
    }

    return (
        <div style={styles.wrapper}>
           <Header 
                user={localStorage.getItem('user')} 
                mode={mode}
                admin={admin}
                handleCenterChange={handleCenterChange}
                logout={() => onLogout({admin: admin, center: center})}
                toggleMode={toggleMode}
                center={center} 
            />
            <div style={styles.content(mode)}>
            { isUploaded ? 
                <div style={styles.uploadWrapper}>

                    <div style={styles.patientDetails(mode)}>
                        <div style={styles.patientDetail}>
                            <p style={{fontSize: '16px', fontWeight: 'bold', marginBottom: '10px'}}>Patient ID</p>
                            <p style={{fontSize: '14px', marginBottom: '10px', marginLeft: '20px'}}>{Object.values(metadata)[0].PatientID}</p>
                        </div>
                        <div style={styles.patientDetail}>
                            <p style={{fontSize: '16px', fontWeight: 'bold', marginBottom: '10px'}}>Patient Name</p>
                            <p style={{fontSize: '14px', marginBottom: '10px', marginLeft: '20px'}}>{Object.values(metadata)[0].PatientName}</p>
                        </div>
                    </div>
                    <div>
                        <p style={{fontSize: '16px', fontWeight: 'bold', marginBottom: '10px'}}>Select Sequence</p>
                        <Select 
                            style={styles.select} 
                            value={sequence} 
                            onChange={(e) => setSequence(e.target.value)}
                        >
                            {
                                Object.keys(metadata).map((key, index) => (
                                    <MenuItem key={index} value={key}>{key}</MenuItem>
                                ))
                            }
                        </Select>
                    </div>
                    <div style={styles.sequenceDetails(mode)}>
                        <div style={styles.sequenceDetail}>
                            <p style={{fontSize: '16px', fontWeight: 'bold', marginBottom: '10px'}}>Series Number</p>
                            <p style={{fontSize: '14px', marginBottom: '10px', marginLeft: '20px'}}>{metadata[sequence].SeriesNumber}</p>
                        </div>
                        <div style={styles.sequenceDetail}>
                            <p style={{fontSize: '16px', fontWeight: 'bold', marginBottom: '10px'}}>Series Description</p>
                            <p style={{fontSize: '14px', marginBottom: '10px', marginLeft: '20px'}}>{metadata[sequence].SeriesDescription}</p>
                        </div>
                        <div style={styles.sequenceDetail}>
                            <p style={{fontSize: '16px', fontWeight: 'bold', marginBottom: '10px'}}>Slice Thickness</p>
                            <p style={{fontSize: '14px', marginBottom: '10px', marginLeft: '20px'}}>{metadata[sequence].SliceThickness}</p>
                        </div>
                        <div style={styles.sequenceDetail}>
                            <p style={{fontSize: '16px', fontWeight: 'bold', marginBottom: '10px'}}>Spacing Between Slices</p>
                            <p style={{fontSize: '14px', marginBottom: '10px', marginLeft: '20px'}}>{metadata[sequence].SpacingBetweenSlices}</p>
                        </div>
                        <div style={styles.sequenceDetail}>
                            <p style={{fontSize: '16px', fontWeight: 'bold', marginBottom: '10px'}}>Pixel Spacing</p>
                            <p style={{fontSize: '14px', marginBottom: '10px', marginLeft: '20px'}}>{metadata[sequence].PixelSpacing}</p>
                        </div>
                    </div>
                    <Button
                        style={styles.viewButton}
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            // get image
                            axios.get(`${baseURL}/sample_sequence_images/${jobId}/${sequence}`, {
                                headers: {
                                    'Authorization': 'Bearer ' + token
                                }
                            }).then((res) => {
                                // base64
                                setImgSrc('data:image/png;base64,' + res.data.image);
                            }).catch((err) => {
                                console.log(err);
                            });
                            setViewModal(true);
                        }}
                    >
                        <VisibilityIcon style={{marginRight: '10px'}} />
                        View
                    </Button>
                    <Button 
                            style={styles.uploadButton}
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={() => {

                                const formData = new FormData();
                                formData.append('job_id', jobId);
                                formData.append('sequence', sequence === '' ? autoSequence : sequence);
                                axios.post(baseURL + '/trigger_pipeline', formData, 
                                {
                                    headers: {
                                        'Authorization': 'Bearer ' + token
                                    }
                                }).then((res) => {
                                    console.log(res);
                                    setJobQueuedModal(true);
                                }).catch((err) => {
                                    console.log(err);
                                })
                            }}
                        >
                            Start Job

                    </Button>
                </div>
                :
                <div style={styles.uploadWrapper}>
                    <p style={{fontSize: '20px', fontWeight: 'bold', marginBottom: '10px'}}>Upload</p>
                    <form className='nii-form' style={styles.form} onSubmit={
                        (e) => {
                            e.preventDefault();
                            if(fileType === 'dcm' && fileRefDcm.current.files.length === 0 || fileType === 'zip' && fileRefZip.current.files.length === 0 || fileType === 'rar' && fileRefRar.current.files.length === 0){
                                setToastMessage('Please select a file');
                                setToastOpen(true);
                                setToastAnchorEl(uploadButtonRef.current);
                                setTimeout(() => {
                                    setToastOpen(false);
                                }, 2000);
                                return;
                            }
                            setIsUploading(true);
                            const req = new XMLHttpRequest();
                            req.open('POST', baseURL + '/upload');
                            req.setRequestHeader('authorization', 'Bearer ' + token);
                            req.onload = () => {
                                const response = JSON.parse(req.response);
                                setIsWaiting(false);
                                setIsUploaded(true);
                                setJobId(response.job_id);
                                setMetadata(response.metadata);
                                setAutoSequence(response.selected_sequence);
                                setSequence(response.selected_sequence);
                                setProgress(0);
                                // console.log(req.response, 'response', JSON.parse(req.response).job_id);
                            }
                            req.upload.onprogress = (e) => {
                                const progress = Math.round((e.loaded / e.total) * 100);
                                setProgress(progress);
                                if(progress >= 97){
                                    setIsUploading(false);
                                    setIsWaiting(true);
                                } 
                            }
                            req.onabortion = () => {
                            }
                            req.onerror = () => {
                                console.log('error');
                            }

                            const formData = new FormData();

                            if (fileType === 'zip') {
                                formData.append('fileType', 'zip');
                                formData.append('file', fileRefZip.current.files[0]);
                                console.log(fileRefZip.current.files[0], 'file');
                            } 
                            else if(fileType === 'rar') {
                                formData.append('fileType', 'rar');
                                formData.append('file', fileRefRar.current.files[0]);
                            }
                            else {
                                formData.append('fileType', 'dcm');
                                for (let i = 0; i < fileRefDcm.current.files.length; i++) {
                                    formData.append('file', fileRefDcm.current.files[i]);
                                }
                            }
			                if(center === 'trial') {
                                formData.append('dataFrom', dataFrom);
                            }
                            req.send(formData);
                        }
                    }>  <div>
                            <p style={{fontSize: '16px', fontWeight: 'bold', marginBottom: '10px'}}>File Type</p>
                            <Select 
                                style={styles.select} 
                                value={fileType} 
                                onChange={(e) => setFileType(e.target.value)}
                            >
                                <MenuItem value={'zip'}>ZIP</MenuItem>
                                <MenuItem value={'rar'}>RAR</MenuItem>
                                <MenuItem value={'dcm'}>DCM</MenuItem>
                            </Select>
                        </div>
                        {
                            center === 'trial' ? 
                                <TextField
                                    style={styles.textField}
                                    label="Center"
                                    // variant="outlined"
                                    value={dataFrom}
                                    onChange={(e) => setDataFrom(e.target.value)}
                                />
                            : null

                        }
                        <div
                            style={styles.selectUpload(mode)}
                            onClick={() => {
                                if(fileType === 'dcm'){
                                    fileRefDcm.current.click();
                                }else if(fileType === 'zip'){
                                    fileRefZip.current.click();
                                }else if(fileType === 'rar'){
                                    fileRefRar.current.click();
                                }
                            }}
                            onMouseOver={(e) => {
                                //decrease opacity
                                e.target.style.opacity = '0.5';
                            }}
                            onMouseOut={(e) => {
                                //increase opacity
                                e.target.style.opacity = '1';
                            }}
                        >
                            <p style={{fontSize: '16px', fontWeight: 'bold', marginBottom: '10px'}}>Select File(s)</p>
                            <UploadIcon style={{fontSize: '50px', color: '#1b4b84', cursor: 'pointer'}}  />
                        </div>
                        <input type="file" ref={fileRefDcm} style={{display: 'none'}} multiple 
                            onChange={(e) => {
                                let filesSelectedStr = 'File(s) Selected: ';
                                for(let i = 0; i < Math.min(e.target.files.length, 3); i++){
                                    filesSelectedStr += e.target.files[i].name + ', ';
                                }
                                if(e.target.files.length > 3){
                                    filesSelectedStr += '...';
                                }
                                setFilesSelected(filesSelectedStr);
                            }}
                        />
                        <input type="file" ref={fileRefZip} style={{display: 'none'}}
                            onChange={(e) => {
                                let filesSelectedStr = 'File(s) Selected: ' + e.target.files[0].name;
                                setFilesSelected(filesSelectedStr);
                            }}
                        />
                        <input type="file" ref={fileRefRar} style={{display: 'none'}} 
                            onChange={(e) => {
                                let filesSelectedStr = 'File(s) Selected: ' + e.target.files[0].name;
                                setFilesSelected(filesSelectedStr);
                            }}
                        />
                        {filesSelected ?
                            <p style={{
                                color: (mode === 'dark' ? '#fff' : '#000')
                            }}>{filesSelected}</p> : null
                        }
                        <Button 
                            style={styles.uploadButton}
                            type="submit"
                            variant="contained"
                            color="primary"
                            ref={uploadButtonRef}
                        >
                            Upload
                        </Button>
                        {isUploading ?
                            <div className='progres-wrap' style={styles.progress}>
                                <LinearProgress
                                    variant="determinate"
                                    value={progress}
                                />
                                {progress} %
                            </div> : null
                        }
                        {
                            isWaiting ?
                            <div className='progres-wrap' style={styles.progress}>
                                <CircularProgress />
                                <p style={{marginTop: '10px'}}>Waiting for server to process your request</p>
                            </div> : null
                        }
                    </form>
                </div>
            }

            </div>
            <Modal
            open={jobQueuedModal}
            onClose={() => setJobQueuedModal(false)}
            >
                <div
                    style={styles.jobQueuedModal}
                >
                    <div style={{height: '10px', marginTop: '2px', marginRight: '2px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                        <CloseIcon
                            style={{cursor: 'pointer'}}
                            onClick={() => setJobQueuedModal(false)}
                        />
                    </div>
                    <h1>Job Queued</h1>
                    <p>
                        You can check the job status for JobId {jobId} in the jobs section.
                    </p>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            navigate('/jobs');
                        }}
                    >Go to Jobs</Button>
                </div>
            </Modal>
            <Modal
                open={viewModal}
                onClose={() => setViewModal(false)}
            >
                <div
                    style={styles.viewModal}
                >
                    <div 
                        style={styles.closeIcon}
                    >
                        <CloseIcon
                            onClick={() => setViewModal(false)}
                        />
                    </div>
                    <h3>{sequence}</h3>
                    <img src={imgSrc} style={{width: '500px', height: '500px'}} />
                    <Button 
                        style={{marginTop: '10px'}}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            axios.get(`${baseURL}/sample_sequence_images/${jobId}/${sequence}`, {
                                headers: {
                                    'Authorization': 'Bearer ' + token
                                }
                            }).then((res) => {
                                // base64
                                setImgSrc('data:image/png;base64,' + res.data.image);
                            }).catch((err) => {
                                console.log(err);
                            });
                        }}
                    >
                        Refresh
                        <RefreshIcon style={{marginLeft: '5px'}} />
                    </Button>
                </div>
            </Modal>
            <Popper open={toastOpen} anchorEl={toastAnchorEl} placement="top-end">
                <div style={styles.popper}>
                    {toastMessage}
                </div>
            </Popper>
        </div>
    );
}
