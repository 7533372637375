import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import "./TasksDND.css";
import { Droppable, Draggable } from "react-beautiful-dnd";
import Tooltip from "@mui/material/Tooltip";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const Container = styled.div`
  border-radius: 0 8px 8px 8px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border: none;
  padding: 10px;
`;

const Title = styled.h3`
  padding: 12px;
  width: 200px;
  margin: 0px;
  border-radius: 40px 170px 0px 0px;
`;

const TaskList = styled.div`
  padding: 3px;
  transistion: background-color 0.2s ease;
  flex-grow: 1;
  min-height: 100px;
`;

const styles = {
  // entries...
  entries: {
    display: "flex",
    flexDirection: "column",
    width: "95%",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 10px 25px 10px",
    // background: 'green',
  },
  entry: (status, findings) => ({
    minHeight: "115px",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    position: "relative",
    background:
      status === "Finished"
        ? findings === true
          ? "#ffcccb"
          : "#aaeecc"
        : "#E0E0E0",
    borderRadius: "6px 20px",
    marginBlock: "4px",
  }),
  entryopen: (status, findings) => ({
    minHeight: "100px",
    display: "flex",
    flexDirection: "column",
    padding: "10px 10px 55px 10px",
    position: "relative",
    background:
      status === "Finished"
        ? findings === true
          ? "#ffcccb"
          : "#aaeecc"
        : "#E0E0E0",
    borderRadius: "6px 20px",
    marginBlock: "4px",
  }),
  entryRow: {
    marginInline: "5px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    // width: "100%",
  },
  entryRowKey: {
    fontSize: "12pt",
    fontWeight: "700",
    color: "#000",
  },
  entryRowValue: {
    fontSize: "12pt",
    fontWeight: "400",
    color: "#000",
  },
  expandButtonWrapper: {
    position: "absolute",
    left: "10px",
    bottom: "12px",
    padding: "10px",
    backgroundColor: "#1b4b84",
    color: "white",
    fontSize: "12px",
    cursor: "pointer",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
  },
  expandButtonWrapperA: {
    position: "absolute",
    left: "33%",
    bottom: "12px",
    padding: "10px",
    backgroundColor: "#1b4b84",
    color: "white",
    fontSize: "12px",
    cursor: "pointer",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
  },
  expandButtonWrapperB: {
    position: "absolute",
    left: "50%",
    bottom: "12px",
    fontSize: "16px",
    cursor: "pointer",
  },
  statusObjAdmin: (status) => ({
    borderRadius: "6px",
    width: "12px",
    height: "12px",
    background:
      status === "Failed" ? "red" : status === "Finished" ? "green" : "#528398",
    marginRight: "4px",
  }),
  statusWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // justifyContent: 'space-between',
  },
};
const Status = ({ statusObj, status }) => {
  return (
    <div style={styles.statusWrapper}>
      <div style={statusObj(status)}></div>
      {status}
    </div>
  );
};

function Task({ task, index, center, statusObj, expanded }) {
  const [expandeditem, setExpandeditem] = useState(false);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const handleCenterChange = (center) => {
    localStorage.setItem("center", center);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    setExpandeditem(expanded);
  }, [expanded]);
  if (expandeditem) {
    return (
      <Draggable draggableId={`${task.jobId}`} key={task.jobId} index={index}>
        {(provided, snapshot) => (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isdragging={snapshot.isDragging.toString()}
          >
            <div style={styles.entryopen(task.status, task.findings)}>
              <div style={styles.entryRow}>
                <div style={styles.entryRowKey}>Job ID</div>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  enterDelay={200}
                  leaveDelay={200}
                  disableInteractive
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Job ID copied to clipboard"
                >
                  <div
                    style={{ ...styles.entryRowValue, cursor: "pointer" }}
                    onClick={() => {
                      navigator.clipboard.writeText(task.jobId);
                      handleTooltipOpen();
                      setTimeout(() => {
                        handleTooltipClose();
                      }, 2000);
                    }}
                  >
                    {task.jobId.substring(0, 8)}
                  </div>
                </Tooltip>
              </div>
              <div style={styles.entryRow}>
                <div style={styles.entryRowKey}>Center</div>
                <div style={styles.entryRowValue}>{task.center}</div>
              </div>
              <div style={styles.entryRow}>
                <div style={styles.entryRowKey}>From</div>
                <div style={styles.entryRowValue}>{task.dataFrom}</div>
              </div>
              <div style={styles.entryRow}>
                <div style={styles.entryRowKey}>Patient ID</div>
                <div style={styles.entryRowValue}>{task.patientId}</div>
              </div>
              <div style={styles.entryRow}>
                <div style={styles.entryRowKey}>Patient Name</div>
                <div style={styles.entryRowValue}>
                  {task.patientName.substring(0, 20)}
                </div>
              </div>
              {center === "aster" ? (
                <div style={styles.entryRow}>
                  <div style={styles.entryRowKey}>Accession Number</div>
                  <div style={styles.entryRowValue}>
                    {task.accessionNumber.substring(0, 20)}
                  </div>
                </div>
              ) : null}
              <div style={styles.entryRow}>
                <div style={styles.entryRowKey}>Status</div>
                <div style={styles.entryRowValue}>
                  <Status status={task.status} statusObj={statusObj} />
                </div>
              </div>
              <div style={styles.entryRow}>
                <div style={styles.entryRowKey}>Received At</div>
                <div style={styles.entryRowValue}>
                  {new Date(task.receivedAt).toLocaleString("en-IN", {
                    timeZone: "Asia/Kolkata",
                    hour12: false,
                  })}
                </div>
              </div>
              <div style={styles.entryRow}>
                <div style={styles.entryRowKey}>Completed At</div>
                <div style={styles.entryRowValue}>
                  {task.completedAt
                    ? new Date(task.completedAt).toLocaleString("en-IN", {
                        timeZone: "Asia/Kolkata",
                        hour12: false,
                      })
                    : ""}
                </div>
              </div>

              <div
                title="Expand Less"
                style={styles.expandButtonWrapper}
                onClick={() => setExpandeditem(false)}
              >
                <ExpandLessIcon
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              </div>
              <div
                title="Open in Annotate Viewer"
                style={styles.expandButtonWrapperA}
              >
                <OpenInNewIcon
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  onClick={() => {
                    handleCenterChange(task.center);
                    navigate(`/view/${task.jobId}`);
                  }}
                />
              </div>
              <div style={styles.expandButtonWrapperB}>
                <p
                  style={{
                    width: "340px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(20%, -200%)",
                  }}
                >
                  {task.qc_user && `User -${task.qc_user.split("@")[0]}`}
                </p>
              </div>
            </div>
          </div>
        )}
      </Draggable>
    );
  } else {
    return (
      <Draggable draggableId={`${task.jobId}`} key={task.jobId} index={index}>
        {(provided, snapshot) => (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isdragging={snapshot.isDragging.toString()}
          >
            <div style={styles.entry(task.status, task.findings)}>
              <div style={styles.entryRow}>
                <div style={styles.entryRowKey}>Job ID</div>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  enterDelay={200}
                  leaveDelay={200}
                  disableInteractive
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Job ID copied to clipboard"
                >
                  <div
                    style={{ ...styles.entryRowValue, cursor: "pointer" }}
                    onClick={() => {
                      navigator.clipboard.writeText(task.jobId);
                      handleTooltipOpen();
                      setTimeout(() => {
                        handleTooltipClose();
                      }, 1500);
                    }}
                  >
                    {task.jobId.substring(0, 8)}
                  </div>
                </Tooltip>
              </div>
              <div style={styles.entryRow}>
                <div style={styles.entryRowKey}>Patient ID</div>
                <div style={styles.entryRowValue}>{task.patientId}</div>
              </div>
              <div style={styles.entryRow}>
                <div style={styles.entryRowKey}>Patient Name</div>
                <div style={styles.entryRowValue}>
                  {task.patientName.substring(0, 20)}
                </div>
              </div>
              <div
                title="Expand More"
                style={styles.expandButtonWrapper}
                onClick={() => setExpandeditem(true)}
              >
                <ExpandMoreIcon
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              </div>
              <div
                title="Open in Annotate Viewer"
                style={styles.expandButtonWrapperA}
              >
                <OpenInNewIcon
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  onClick={() => {
                    handleCenterChange(task.center);
                    navigate(`/view/${task.jobId}`);
                  }}
                />
              </div>
              <div style={styles.expandButtonWrapperB}>
                <p
                  style={{
                    width: "340px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(20%, -200%)",
                  }}
                >
                  {task.qc_user && `User -${task.qc_user.split("@")[0]}`}
                </p>
              </div>
            </div>
          </div>
        )}
      </Draggable>
    );
  }
}

export default function Column({ title, tasks, id }) {
  const [expanded, setExpanded] = useState(Array(tasks.length).fill(false));
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "30%",
        height: "70vh",
      }}
    >
      <Title
        style={{
          backgroundColor: id == 1 ? "#f0ca8180" : "#b8ebb080",
          position: "stick",
        }}
        className="titleTop"
      >
        {title}
      </Title>
      <Container
        className="column"
        style={{
          backgroundColor: id == 1 ? "#f0ca8180" : "#b8ebb080",
        }}
      >
        <Droppable droppableId={id}>
          {(provided, snapshot) => (
            <TaskList
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
            >
              {tasks.map((task, index) => (
                <Task
                  key={index}
                  index={index}
                  task={task}
                  center={task.center}
                  statusObj={styles.statusObjAdmin}
                  expanded={expanded[index]}
                />
              ))}
              {provided.placeholder}
            </TaskList>
          )}
        </Droppable>
      </Container>
    </div>
  );
}
