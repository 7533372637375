import "./App.css";
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from "react-router-dom";
import Login from "./Login";
import Jobs from "./Jobs";
import Render3D from './Render3D';
import Report from './Report';
import View from './View';
import Upload from './Upload';
import PasswordReset from './PasswordReset';
import Analytics from './Analytics';
import AdminSignUp from "./AdminSignUp";
import { AdminAddTable } from "./AdminAddTable";
import JobsAll from "./JobsAll";
import KanbanBoard from "./TaskList";
import CustomizedGatewayTable from "./GatewayStatus";

function App() {
        

      
  return (
    <div className="app" style={{ width: "100%", height: "100%" }}>
      <Router basename="dashboard">
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/jobs" element={<Jobs />} />
          <Route exact path="/jobsall" element={<JobsAll />} />
          <Route exact path="/analytics" element={<Analytics />} />
          <Route exact path="/password_reset" element={<PasswordReset />} />

          {/* <Route exact path="/new" element={<PrivateRoute component={UploadFiles}/>}/> */}

          <Route exact path="/render/:jobId" element={<Render3D />} />
          <Route exact path="/render/:jobId/:accessId" element={<Render3D />} />
          <Route
            exact
            path="/render/:centerName/:jobId/:accessId"
            element={<Render3D />}
          />
          <Route exact path="/report/:jobId" element={<Report />} />

          <Route exact path="/view/:jobId" element={<View />} />

          <Route
            exact
            path="/admin/user-management"
            element={<AdminAddTable />}
          />
          <Route
            exact
            path="/admin/add-user"
            element={<AdminSignUp admin={true} />}
          />
          <Route exact path="/admin/login" element={<Login admin={true} />} />
          <Route exact path="/admin/jobs" element={<Jobs admin={true} />} />
          <Route
            exact
            path="/admin/jobsall"
            element={<JobsAll admin={true} />}
          />
          <Route
            exact
            path="/admin/render/:jobId"
            element={<Render3D admin={true} />}
          />
          <Route
            exact
            path="/admin/report/:jobId"
            element={<Report admin={true} />}
          />

          <Route exact path="/admin/upload" element={<Upload />} />

          <Route exact path="/admin/tasks" element={<KanbanBoard />} />
          <Route
            exact
            path="/admin/gatewaystatus"
            element={<CustomizedGatewayTable />}
          />
          <Route exact path="/" element={<Navigate to="/jobs" />} />
        </Routes>
      </Router>
    </div>
  );
}
export default App;
