const styles = {
  root: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
    overflow: "scroll",
  },
  body: {
    backgroundColor: "#fbfffe",
    width: "100%",
    display: "block",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0",
    margin: "0",
  },
  header: {
    backgroundColor: "#1b4a92",
    color: "#fff",
    width: "100%",
    height: "150px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "20px",
    justifyContent: "space-between",
  },
  header2: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headerLeft: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-around",
    paddingLeft: "10px",
    height: "100%",
    paddingBottom: "10px",
  },
  headerRight: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "space-around",
    paddingRight: "10px",
    width: "100%",
  },
  patientText: {
    fontSize: "15pt",
    fontWeight: "800",
    display: "flex",
    // width: '80%',
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  content: {
    width: "100%",
    overflow: "scroll",
    paddingLeft: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  calculusTable: {
    margin: "10px",
    width: "90%",
    backgroundColor: "#eee",
    border: "2px solid #000",
    borderRadius: "1px",
  },
  kidneyTable: {
    margin: "10px",
    width: "90%",
    backgroundColor: "#eee",
    border: "2px solid #000",
    borderRadius: "1px",
  },
  tableHeaderItem: {
    backgroundColor: "#1b4a92",
    color: "#ffffff",
    padding: "10px",
    fontWeight: "800",
    fontSize: "14pt",
    height: "30px",
    fontFamily: "Courier New, Courier, monospace",
  },
  tableItem: {
    backgroundColor: "#fff",
    color: "#000",
    padding: "10px",
    fontSize: "12pt",
    minHeight: "30px",
    minWidth: "80px",
    fontFamily: "Courier New, Courier, monospace",
    textAlign: "center",
  },
  hydroureterTableWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingLeft: "100px",
  },
  hydroureterTable: {
    border: "none",
  },
  hydroureterTableTd: {
    border: "none",
    paddingLeft: "10px",
    fontSize: "12pt",
    fontWeight: "600",
    color: "#000",
  },
  kidneyTableWrapper: {
    width: "100%",

    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingLeft: "100px",
  },
  kidneyTableTd: {
    border: "none",
    paddingLeft: "10px",
    fontSize: "12pt",
    fontWeight: "600",
    color: "#000",
  },
  keySlicesContent: {
    display: "block",
    width: "100%",
  },
  keySlicesContentWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingLeft: "100px",
  },
  keySlicesContentTable: {
    border: "none",
  },
  keySlicesContentTableTd: {
    border: "none",
    paddingLeft: "10px",
    fontSize: "12pt",
    fontWeight: "600",
    color: "#000",
  },

  keySlicesWrapper: {
    margin: "10px",
    width: "95%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingLeft: "100px",
    marginTop: "30px",
    pageBreakInside: "avoid",
  },
  keySlicesImages: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "10px",
    marginBottom: "30px",
  },
  keySlicesImage: {
    marginRight: "20px",
  },
  calculusTable: {
    margin: "10px",
    width: "90%",
    backgroundColor: "#eee",
    border: "2px solid #000",
    borderRadius: "1px",
  },
  calculusTableTd: {
    border: "none",
    paddingLeft: "10px",
    fontSize: "12pt",
    fontWeight: "600",

    color: "#000",
  },
  keySlicesTable: {
    marginLeft: "10px",
    marginRight: "10px",
    backgroundColor: "#1b4a92",
    width: "80%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "10px",
    marginBottom: "30px",
  },
  keySlicesTableTd: {
    color: "#fff",
  },
  nextPage: {
    pageBreakBefore: "always",
    paddingTop: "20px",
  },
  impressionWrapper: {
    marginLeft: "20px",
    width: "100%",
  },
  impressionText: {
    fontSize: "11pt",
    marginBottom: "5px",
    fontWeight: 500,
  },
  impressionsList: {
    width: "100%",
    marginLeft: "10%",
  },
  impressionListItem: {
    display: "flex",
    flexDirection: "row",
    maxWidth: "80%",
  },
  editIcon: {
    marginLeft: "10px",
    cursor: "pointer",
    color: "#049c77",
  },
  deleteIcon: {
    color: "red",
    cursor: "pointer",
  },
  textStyle: {
    fontSize: "10pt",
    fontWeight: "400",
  },
  editInput: (width) => ({
    // width: '100%',
    width: width,
    height: "30px",
    border: "none",
    borderRadius: "1px",
    paddingLeft: "10px",
    fontSize: "10pt",
  }),
  editInputWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  editRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  downloadIcon: {
    marginLeft: "10px",
    cursor: "pointer",
  },
  downloadButton: {
    width: "150px",
    height: "30px",
    backgroundColor: "#049c77",
    margin: "10px",
    color: "#fff",
  },
  saveButton: {
    width: "150px",
    height: "30px",
    backgroundColor: "#049c77",
    margin: "10px",
    color: "#fff",
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    border: "2px solid #000",
    borderRadius: "1px",
    width: "50%",
    height: "50%",
    padding: "20px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // zIndex: '100',
    overflow: "auto",
    // display: 'none',
    opacity: 1,
  },
  modalHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  modalHeaderText: {
    fontSize: "14pt",
    fontWeight: "600",
    color: "#000",
  },
  modalBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "20px",
    paddingRight: "20px",
    marginBottom: "20px",
  },
  modalBodyRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px",
    width: "80%",
  },
  modalBodyRowItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px",
    width: "100%",
  },
  modalBodyRowItemText: {
    fontSize: "14pt",
    fontWeight: "600",
    color: "#000",
  },
  modalFooter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "20px",
    paddingRight: "20px",
    marginBottom: "20px",
  },
  otherAbdomenOrgansWrapper: {
    width: "100%",
    paddingLeft: "10%",
  },
  abdomenOrganHeader: {
    fontSize: "16pt",
    fontWeight: "700",
    fontFamily: "Times New Roman",
  },
  pageHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "center",
    paddingLeft: "20px",
    paddingRight: "20px",
    maxWidth: "200px",
    background: "#888",
    height: "100vh",
  },
  configsSelect: {
    width: "100%",
    height: "30px",
    color: "#fff",
  },
};

export default styles;
