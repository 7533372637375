// export const baseURL = "https://urologiq.biocliq.ai/aarthi_server/infer";
// export const baseURLAPI = "https://urologic.biocliq.ai/aarthi_server/api";
export const inferURLs = {
  //    "aarthi": "https://urologic.biocliq.ai/aarthi_server/infer",
  // "aarthi": "https://inference2.urologiq.ai/aarthi_cropped_server/infer",
  // "prima": "https://inference1.urologiq.ai/prima_server/infer",
  // "gujimg": "https://inference1.urologiq.ai/gujarat_server/infer",
  // "test-demo": "https://inference1.urologiq.ai/test_demo_server/infer",
  // "medimax": "https://inference4.urologiq.ai/urologiq_test/medimax_server/infer",
  // "jiva-cropped": "https://inference1.urologiq.ai/jiva_kub_cropped/infer",
  //    "kauvery": "https://inference1.urologiq.ai/kauvery/infer",
  //    "gic_cropped": "https://inference4.urologiq.ai/gic_cropped/infer",
  sahyadri: "https://inference4.urologiq.ai/urologiq_test/sahyadri/infer",
  // "ths_hosp": "https://inference4.urologiq.ai/urologiq_test/ths/infer",
  // "nanavati": "https://inference4.urologiq.ai/urologiq_test/nanavati/infer",
  // "5c": "https://inference4.urologiq.ai/urologiq_test/5c/infer",
  aarthi: "https://inference4.urologiq.ai/urologiq_test/aarthi/infer",
  //agnapp: "https://inference4.urologiq.ai/urologiq_test/agnapp/infer",
  alpha: "https://inference4.urologiq.ai/urologiq_test/alpha/infer",
  ainu: "https://inference4.urologiq.ai/urologiq_test/ainu/infer",
  ambikey: "https://inference4.urologiq.ai/urologiq_test/ambikey/infer",
  apollo: "https://inference4.urologiq.ai/urologiq_test/apollo/infer",
  advimaging: "https://inference4.urologiq.ai/urologiq_test/advimaging/infer",
  //blde: "https://inference4.urologiq.ai/urologiq_test/blde/infer",
  //curi: "https://inference4.urologiq.ai/urologiq_test/curi/infer",
  cect: "https://inference4.urologiq.ai/urologiq_test/cect/infer",
  trial: "https://inference4.urologiq.ai/urologiq_test/trial/infer",
  //aster: "https://inference4.urologiq.ai/urologiq_test/aster/infer",
  ayucare: "https://inference4.urologiq.ai/urologiq_test/ayucare/infer",
  gic_mnagar: "https://inference4.urologiq.ai/urologiq_test/gic_mnagar/infer",
  //apollo_hyd: "https://inference4.urologiq.ai/urologiq_test/apollo_hyd/infer",
  carpl: "https://inference4.urologiq.ai/urologiq_test/carpl/infer",
  // cropped_beta: "https://inference1.urologiq.ai/cropped_beta/infer",
  megavision: "https://inference4.urologiq.ai/urologiq_test/megavision/infer",
  //deeptek: "https://inference4.urologiq.ai/urologiq_test/deeptek/infer",
  gic_sadbhav: "https://inference4.urologiq.ai/urologiq_test/gic_sadbhav/infer",
  elite: "https://inference4.urologiq.ai/urologiq_test/elite/infer",
  //genx: "https://inference4.urologiq.ai/urologiq_test/genx/infer",
  gic: "https://inference4.urologiq.ai/urologiq_test/gic/infer",
  gicprime: "https://inference4.urologiq.ai/urologiq_test/gicprime/infer",
  godavari: "https://inference4.urologiq.ai/urologiq_test/godavari/infer",
  //healthsquare:
  //  "https://inference4.urologiq.ai/urologiq_test/healthsquare/infer",
  //imagebytes: "https://inference4.urologiq.ai/urologiq_test/imagebytes/infer",
  //ishan: "https://inference4.urologiq.ai/urologiq_test/ishan/infer",
  sathyasai: "https://inference4.urologiq.ai/urologiq_test/sathyasai/infer",
  kauvery: "https://inference4.urologiq.ai/urologiq_test/kauvery/infer",
  kims: "https://inference4.urologiq.ai/urologiq_test/kims/infer",
  kims_kp: "https://inference4.urologiq.ai/urologiq_test/kims_kp/infer",
  radcare: "https://inference4.urologiq.ai/urologiq_test/radcare/infer",
  lifeline: "https://inference4.urologiq.ai/urologiq_test/lifeline/infer",
  // medimax: "https://inference4.urologiq.ai/urologiq_test/medimax/infer",
  mediscan: "https://inference4.urologiq.ai/urologiq_test/mediscan/infer",
  //newmedd: "https://inference4.urologiq.ai/urologiq_test/newmedd/infer",
  // medsynaptic: "https://inference4.urologiq.ai/urologiq_test/medsynaptic/infer",
  meenakshi: "https://inference4.urologiq.ai/urologiq_test/meenakshi/infer",
  meenakshi_m: "https://inference4.urologiq.ai/urologiq_test/meenakshi_m/infer",
  meenakshi_b: "https://inference4.urologiq.ai/urologiq_test/meenakshi_b/infer",
  //sreeimaging: "https://inference4.urologiq.ai/urologiq_test/sreeimaging/infer",
  neoshealthtech:
    "https://inference4.urologiq.ai/urologiq_test/neoshealthtech/infer",
  nidan: "https://inference4.urologiq.ai/urologiq_test/nidan/infer",
  nirman: "https://inference4.urologiq.ai/urologiq_test/nirman/infer",
  niramay: "https://inference4.urologiq.ai/urologiq_test/niramay/infer",
  ozone: "https://inference4.urologiq.ai/urologiq_test/ozone/infer",
  //orbit: "https://inference4.urologiq.ai/urologiq_test/orbit/infer",
  //prima: "https://inference4.urologiq.ai/urologiq_test/prima/infer",
  raviscans: "https://inference4.urologiq.ai/urologiq_test/raviscans/infer",
  isha: "https://inference4.urologiq.ai/urologiq_test/isha/infer",
  //prohealth: "https://inference4.urologiq.ai/urologiq_test/prohealth/infer",
  //rubycare: "https://inference4.urologiq.ai/urologiq_test/rubycare/infer",
  //sardarpatel: "https://inference4.urologiq.ai/urologiq_test/sardarpatel/infer",
  // sagar: "https://inference4.urologiq.ai/urologiq_test/sagar/infer",
  //sadbhavna: "https://inference4.urologiq.ai/urologiq_test/sadbhavna/infer",
  siddhivinayak:
    "https://inference4.urologiq.ai/urologiq_test/siddhivinayak/infer",
  sukhmani: "https://inference4.urologiq.ai/urologiq_test/sukhmani/infer",
  //abhiscans: "https://inference4.urologiq.ai/urologiq_test/abhiscans/infer",
  test2: "https://inference4.urologiq.ai/urologiq_test/test2/infer",
  tmu: "https://inference4.urologiq.ai/urologiq_test/tmu/infer",
  thsdiag: "https://inference4.urologiq.ai/urologiq_test/thsdiag/infer",
  vijaya: "https://inference4.urologiq.ai/urologiq_test/vijaya/infer",
  //yashoda: "https://inference4.urologiq.ai/urologiq_test/yashoda/infer",
};
export const apiURLs = {
  //    "aarthi": "https://urologic.biocliq.ai/aarthi_server/api",
  // "aarthi": "https://inference2.urologiq.ai/aarthi_cropped_server/api",
  // "prima": "https://inference1.urologiq.ai/prima_server/api",
  // "gujimg": "https://inference1.urologiq.ai/gujarat_server/api",
  // "test-demo": "https://inference1.urologiq.ai/test_demo_server/api",
  // "medimax": "https://inference4.urologiq.ai/urologiq_test/medimax_server/api",
  // "jiva-cropped": "https://inference1.urologiq.ai/jiva_kub_cropped/api",
  //   "kauvery": "https://inference1.urologiq.ai/kauvery/api",
  //   "gic_cropped": "https://inference4.urologiq.ai/gic_cropped/api",
  sahyadri: "https://inference4.urologiq.ai/urologiq_test/sahyadri/api",
  // "ths_hosp": "https://inference4.urologiq.ai/urologiq_test/ths/api",
  // "nanavati": "https://inference4.urologiq.ai/urologiq_test/nanavati/api",
  // "5c": "https://inference4.urologiq.ai/urologiq_test/5c/api",
  aarthi: "https://inference4.urologiq.ai/urologiq_test/aarthi/api",
  trial: "https://inference4.urologiq.ai/urologiq_test/trial/api",
  //aster: "https://inference4.urologiq.ai/urologiq_test/aster/api",
  ayucare: "https://inference4.urologiq.ai/urologiq_test/ayucare/api",
  gic_mnagar: "https://inference4.urologiq.ai/urologiq_test/gic_mnagar/api",
  //apollo_hyd: "https://inference4.urologiq.ai/urologiq_test/apollo_hyd/api",
  carpl: "https://inference4.urologiq.ai/urologiq_test/carpl/api",
  // cropped_beta: "https://inference1.urologiq.ai/cropped_beta/api",
  megavision: "https://inference4.urologiq.ai/urologiq_test/megavision/api",
  cect: "https://inference4.urologiq.ai/urologiq_test/cect/api",
  //deeptek: "https://inference4.urologiq.ai/urologiq_test/deeptek/api",
  gic_sadbhav: "https://inference4.urologiq.ai/urologiq_test/gic_sadbhav/api",
  elite: "https://inference4.urologiq.ai/urologiq_test/elite/api",
  //genx: "https://inference4.urologiq.ai/urologiq_test/genx/api",
  gic: "https://inference4.urologiq.ai/urologiq_test/gic/api",
  godavari: "https://inference4.urologiq.ai/urologiq_test/godavari/api",
  //healthsquare: "https://inference4.urologiq.ai/urologiq_test/healthsquare/api",
  //imagebytes: "https://inference4.urologiq.ai/urologiq_test/imagebytes/api",
  //ishan: "https://inference4.urologiq.ai/urologiq_test/ishan/api",
  kauvery: "https://inference4.urologiq.ai/urologiq_test/kauvery/api",
  radcare: "https://inference4.urologiq.ai/urologiq_test/radcare/api",
  lifeline: "https://inference4.urologiq.ai/urologiq_test/lifeline/api",
  // medimax: "https://inference4.urologiq.ai/urologiq_test/medimax/api",
  mediscan: "https://inference4.urologiq.ai/urologiq_test/mediscan/api",
  //newmedd: "https://inference4.urologiq.ai/urologiq_test/newmedd/api",
  // medsynaptic: "https://inference4.urologiq.ai/urologiq_test/medsynaptic/api",
  //sreeimaging: "https://inference4.urologiq.ai/urologiq_test/sreeimaging/api",
  neoshealthtech:
    "https://inference4.urologiq.ai/urologiq_test/neoshealthtech/api",
  nidan: "https://inference4.urologiq.ai/urologiq_test/nidan/api",
  nirman: "https://inference4.urologiq.ai/urologiq_test/nirman/api",
  niramay: "https://inference4.urologiq.ai/urologiq_test/niramay/api",
  ozone: "https://inference4.urologiq.ai/urologiq_test/ozone/api",
  //prima: "https://inference4.urologiq.ai/urologiq_test/prima/api",
  isha: "https://inference4.urologiq.ai/urologiq_test/isha/api",
  //sardarpatel: "https://inference4.urologiq.ai/urologiq_test/sardarpatel/api",
  // sagar: "https://inference4.urologiq.ai/urologiq_test/sagar/api",
  sukhmani: "https://inference4.urologiq.ai/urologiq_test/sukhmani/api",
  //abhiscans: "https://inference4.urologiq.ai/urologiq_test/abhiscans/api",
  test2: "https://inference4.urologiq.ai/urologiq_test/test2/api",
  thsdiag: "https://inference4.urologiq.ai/urologiq_test/thsdiag/api",
  meenakshi: "https://inference4.urologiq.ai/urologiq_test/meenakshi/api",
  //curi: "https://inference4.urologiq.ai/urologiq_test/curi/api",
  raviscans: "https://inference4.urologiq.ai/urologiq_test/raviscans/api",
  //orbit: "https://inference4.urologiq.ai/urologiq_test/orbit/api",
  vijaya: "https://inference4.urologiq.ai/urologiq_test/vijaya/api",
  //rubycare: "https://inference4.urologiq.ai/urologiq_test/rubycare/api",
  advimaging: "https://inference4.urologiq.ai/urologiq_test/advimaging/api",
  ainu: "https://inference4.urologiq.ai/urologiq_test/ainu/api",
  ambikey: "https://inference4.urologiq.ai/urologiq_test/ambikey/api",
  siddhivinayak:
    "https://inference4.urologiq.ai/urologiq_test/siddhivinayak/api",
  meenakshi_m: "https://inference4.urologiq.ai/urologiq_test/meenakshi_m/api",
  //prohealth: "https://inference4.urologiq.ai/urologiq_test/prohealth/api",
  gicprime: "https://inference4.urologiq.ai/urologiq_test/gicprime/api",
  alpha: "https://inference4.urologiq.ai/urologiq_test/alpha/api",
  apollo: "https://inference4.urologiq.ai/urologiq_test/apollo/api",
  meenakshi_b: "https://inference4.urologiq.ai/urologiq_test/meenakshi_b/api",
  tmu: "https://inference4.urologiq.ai/urologiq_test/tmu/api",
  kims: "https://inference4.urologiq.ai/urologiq_test/kims/api",
  kims_kp: "https://inference4.urologiq.ai/urologiq_test/kims_kp/api",
  //agnapp: "https://inference4.urologiq.ai/urologiq_test/agnapp/api",
  sathyasai: "https://inference4.urologiq.ai/urologiq_test/sathyasai/api",
  //sadbhavna: "https://inference4.urologiq.ai/urologiq_test/sadbhavna/api",
  //blde: "https://inference4.urologiq.ai/urologiq_test/blde/api",
  //yashoda: "https://inference4.urologiq.ai/urologiq_test/yashoda/api",
};
export const ohifURLs = {
  //   "aarthi": "https://urologic.biocliq.ai/aarthi/ohif_viewer/viewer",
  // "aarthi": "https://inference2.urologiq.ai/aarthi_cropped/ohif_viewer/viewer",
  jivaa: "https://inference1.urologiq.ai/ohif_viewer/viewer",
  // "prima": "https://inference1.urologiq.ai/prima/ohif_viewer/viewer",
  // "gujimg": "https://inference1.urologiq.ai/gujarat/ohif_viewer/viewer",
  // "test-demo": "https://inference1.urologiq.ai/test_demo/ohif_viewer/viewer",
  cropped_beta:
    "https://inference1.urologiq.ai/cropped_beta/ohif_viewer/viewer",
  megavision: 
    "https://inference4.urologiq.ai/urologiq_test/megavision/ohif_viewer/viewer",
  // "medimax": "https://inference4.urologiq.ai/medimax/urologiq_test/ohif_viewer/viewer",
  // "jiva-cropped": "https://inference1.urologiq.ai/jiva_kub_cropped/ohif_viewer/viewer",
  //   "kauvery": "https://inference1.urologiq.ai/kauvery/ohif_viewer/viewer",
  kauvery:
    "https://inference4.urologiq.ai/urologiq_test/kauvery/ohif_viewer/viewer",
  radcare:
    "https://inference4.urologiq.ai/urologiq_test/radcare/ohif_viewer/viewer",
  //   "gic_cropped": "https://inference4.urologiq.ai/gic_cropped/ohif_viewer/viewer",
  nirman:
    "https://inference4.urologiq.ai/urologiq_test/nirman/ohif_viewer/viewer",
  neoshealthtech:
    "https://inference4.urologiq.ai/urologiq_test/neoshealthtech/ohif_viewer/viewer",
  test2:
    "https://inference4.urologiq.ai/urologiq_test/test2/ohif_viewer/viewer",
  gic: "https://inference4.urologiq.ai/urologiq_test/gic/ohif_viewer/viewer",
  godavari:
    "https://inference4.urologiq.ai/urologiq_test/godavari/ohif_viewer/viewer",
  //healthsquare:
  //  "https://inference4.urologiq.ai/urologiq_test/healthsquare/ohif_viewer/viewer",
  ozone:
    "https://inference4.urologiq.ai/urologiq_test/ozone/ohif_viewer/viewer",
  //prima: "https://inference4.urologiq.ai/urologiq_test/prima/ohif_viewer/viewer",
  mediscan:
    "https://inference4.urologiq.ai/urologiq_test/mediscan/ohif_viewer/viewer",
  //newmedd: "https://inference4.urologiq.ai/urologiq_test/newmedd/ohif_viewer/viewer",
  trial:
    "https://inference4.urologiq.ai/urologiq_test/trial/ohif_viewer/viewer",
  medimax:
    "https://inference4.urologiq.ai/urologiq_test/medimax/ohif_viewer/viewer",
  "5c": "https://inference4.urologiq.ai/urologiq_test/5c/ohif_viewer/viewer",
  sahyadri:
    "https://inference4.urologiq.ai/urologiq_test/sahyadri/ohif_viewer/viewer",
  // "ths_hosp": "https://inference4.urologiq.ai/urologiq_test/ths/ohif_viewer/viewer",
  lifeline:
    "https://inference4.urologiq.ai/urologiq_test/lifeline/ohif_viewer/viewer",
  ayucare:
    "https://inference4.urologiq.ai/urologiq_test/ayucare/ohif_viewer/viewer",
  //deeptek:
  //  "https://inference4.urologiq.ai/urologiq_test/deeptek/ohif_viewer/viewer",
  elite:
    "https://inference4.urologiq.ai/urologiq_test/elite/ohif_viewer/viewer",
  aarthi:
    "https://inference4.urologiq.ai/urologiq_test/aarthi/ohif_viewer/viewer",
  // "nanavati": "https://inference4.urologiq.ai/urologiq_test/nanavati/ohif_viewer/viewer",
  //sardarpatel: "https://inference4.urologiq.ai/urologiq_test/sardarpatel/ohif_viewer/viewer",
  gic_mnagar:
    "https://inference4.urologiq.ai/urologiq_test/gic_mnagar/ohif_viewer/viewer",
  //apollo_hyd: "https://inference4.urologiq.ai/urologiq_test/apollo_hyd/ohif_viewer/viewer",
  //genx: "https://inference4.urologiq.ai/urologiq_test/genx/ohif_viewer/viewer",
  //ishan: "https://inference4.urologiq.ai/urologiq_test/ishan/ohif_viewer/viewer",
  //abhiscans: "https://inference4.urologiq.ai/urologiq_test/abhiscans/ohif_viewer/viewer",
  // sagar: "https://inference4.urologiq.ai/urologiq_test/sagar/ohif_viewer/viewer",
  sukhmani:
    "https://inference4.urologiq.ai/urologiq_test/sukhmani/ohif_viewer/viewer",
  //aster: "https://inference4.urologiq.ai/urologiq_test/aster/ohif_viewer/viewer",
  ths: "https://inference4.urologiq.ai/urologiq_test/thsdiag/ohif_viewer/viewer",
  thsdiag:
    "https://inference4.urologiq.ai/urologiq_test/thsdiag/ohif_viewer/viewer",
  // medsynaptic: "https://inference4.urologiq.ai/urologiq_test/medsynaptic/ohif_viewer/viewer",
  //imagebytes: "https://inference4.urologiq.ai/urologiq_test/imagebytes/ohif_viewer/viewer",
  carpl:
    "https://inference4.urologiq.ai/urologiq_test/carpl/ohif_viewer/viewer",
  nidan:
    "https://inference4.urologiq.ai/urologiq_test/nidan/ohif_viewer/viewer",
  niramay:
    "https://inference4.urologiq.ai/urologiq_test/niramay/ohif_viewer/viewer",
  isha: "https://inference4.urologiq.ai/urologiq_test/isha/ohif_viewer/viewer",
  //sreeimaging: "https://inference4.urologiq.ai/urologiq_test/sreeimaging/ohif_viewer/viewer",
  gic_sadbhav:
    "https://inference4.urologiq.ai/urologiq_test/gic_sadbhav/ohif_viewer/viewer",
  meenakshi:
    "https://inference4.urologiq.ai/urologiq_test/meenakshi/ohif_viewer/viewer",
  //curi: "https://inference4.urologiq.ai/urologiq_test/curi/ohif_viewer/viewer",
  raviscans:
    "https://inference4.urologiq.ai/urologiq_test/raviscans/ohif_viewer/viewer",
  //orbit: "https://inference4.urologiq.ai/urologiq_test/orbit/ohif_viewer/viewer",
  vijaya:
    "https://inference4.urologiq.ai/urologiq_test/vijaya/ohif_viewer/viewer",
  //rubycare: "https://inference4.urologiq.ai/urologiq_test/rubycare/ohif_viewer/viewer",
  advimaging:
    "https://inference4.urologiq.ai/urologiq_test/advimaging/ohif_viewer/viewer",
  ainu: "https://inference4.urologiq.ai/urologiq_test/ainu/ohif_viewer/viewer",
  ambikey:
    "https://inference4.urologiq.ai/urologiq_test/ambikey/ohif_viewer/viewer",
  siddhivinayak:
    "https://inference4.urologiq.ai/urologiq_test/siddhivinayak/ohif_viewer/viewer",
  meenakshi_m:
    "https://inference4.urologiq.ai/urologiq_test/meenakshi_m/ohif_viewer/viewer",
  //prohealth: "https://inference4.urologiq.ai/urologiq_test/prohealth/ohif_viewer/viewer",
  gicprime:
    "https://inference4.urologiq.ai/urologiq_test/gicprime/ohif_viewer/viewer",
  alpha:
    "https://inference4.urologiq.ai/urologiq_test/alpha/ohif_viewer/viewer",
  apollo:
    "https://inference4.urologiq.ai/urologiq_test/apollo/ohif_viewer/viewer",
  meenakshi_b:
    "https://inference4.urologiq.ai/urologiq_test/meenakshi_b/ohif_viewer/viewer",
  tmu: "https://inference4.urologiq.ai/urologiq_test/tmu/ohif_viewer/viewer",
  kims: 
    "https://inference4.urologiq.ai/urologiq_test/kims/ohif_viewer/viewer",
  kims_kp: 
    "https://inference4.urologiq.ai/urologiq_test/kims_kp/ohif_viewer/viewer",
  //agnapp: "https://inference4.urologiq.ai/urologiq_test/agnapp/ohif_viewer/viewer",
  sathyasai:
    "https://inference4.urologiq.ai/urologiq_test/sathyasai/ohif_viewer/viewer",
  cect: "https://inference4.urologiq.ai/urologiq_test/cect/ohif_viewer/viewer",
  //sadbhavna: "https://inference4.urologiq.ai/urologiq_test/sadbhavna/ohif_viewer/viewer",
  //blde: "https://inference4.urologiq.ai/urologiq_test/blde/ohif_viewer/viewer",
  //yashoda: "https://inference4.urologiq.ai/urologiq_test/yashoda/ohif_viewer/viewer",
};

export const analyticsAPIs = {
  inference4:
    "https://inference4.urologiq.ai/urologiq_test/aarthi/api/analytics",
};

export const allColors = [
  // [0, 0, 0],
  [128, 174, 128],
  [241, 214, 145],
  [177, 122, 101],
  [111, 184, 210],
  [216, 101, 79],
  [221, 130, 101],
  [144, 238, 144],
  [220, 245, 20],
  [192, 104, 88],
  [78, 63, 0],
  [255, 250, 220],
  [230, 220, 70],
  [200, 200, 235],
  [250, 250, 210],
  [244, 214, 49],
  [0, 151, 206],
];

// red
export const stoneColors = [
  [255, 0, 0],
  [128, 0, 0],
  [192, 0, 0],
  [255, 0, 0],
  [128, 0, 0],
  [192, 0, 0],
  [255, 0, 0],
  [128, 0, 0],
  [192, 0, 0],
  [255, 0, 0],
  [128, 0, 0],
  [192, 0, 0],
  [255, 0, 0],
  [128, 0, 0],
  [192, 0, 0],
  [255, 0, 0],
  [128, 0, 0],
  [192, 0, 0],
  [255, 0, 0],
  [128, 0, 0],
  [192, 0, 0],
  [255, 0, 0],
];

