import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import "./Jobs.css";
// import http from "./http-common";
import axios from "axios";

import ViewInArIcon from "@mui/icons-material/ViewInAr";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Popper from "@mui/material/Popper";
import TextField from "@mui/material/TextField";
import BarChartIcon from "@mui/icons-material/BarChart";
import ListIcon from "@mui/icons-material/List";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import MenuIcon from "@mui/icons-material/Menu";

import GetAppIcon from "@mui/icons-material/GetApp";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SearchIcon from "@mui/icons-material/Search";
import DeleteForever from "@mui/icons-material/DeleteForever";
import SendIcon from "@mui/icons-material/Send";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import SummarizeIcon from "@mui/icons-material/Summarize";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ProfileIcon from "@mui/icons-material/AccountCircle";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

import { inferURLs, apiURLs, ohifURLs, analyticsAPIs } from "./config";
import { CircularProgress } from "@mui/material";

import { onLogout } from "./Login";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RefreshIcon from "@mui/icons-material/Refresh";
import CommentIcon from "@mui/icons-material/Comment";

import { getRandomColor, createImageFromInitials } from "./utils/Utils";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import LockResetIcon from "@mui/icons-material/LockReset";

import { Select, MenuItem } from "@mui/material";

import logo from "./urologiq.png";

import * as d3 from "d3";

import {
  FindingsMetrics,
  PFSMetrics,
  HNMetrics,
  CalculiMetrics,
} from "./MetricsPlot";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  pt: 2,
  px: 4,
  pb: 3,
};

const styles = {
  wrapper: {
    width: "100%",
    height: "100%",
    // margin: '10px',
    // padding: '20px',
    background: "#fff",
    color: "black",
    // overflow: "hidden",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    background: "#1b4a92",
    height: "40px",
    alignItems: "center",
    padding: "5px",
    color: "#fff",
  },
  dashboardWrapper: {
    // height: '100%',
    display: "flex",
    flexDirection: "row",
  },
  dashboardSelect: {
    fontSize: "20px",
    fontWeight: "700",
    color: "#fff",
    fontFamily: "Times New Roman",
    height: "40px",
    padding: "0px",
    margin: "0px",
  },
  dashboardHeaderText: {
    fontSize: "20px",
    fontWeight: "700",
    color: "#fff",
    fontFamily: "Times New Roman",
  },
  menuButtonWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginRight: "20px",
  },
  menuButton: {
    cursor: "pointer",
  },
  logoutButton: {
    color: "#fff",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  drawer: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 20px",
    width: "220px",
    height: "calc(100vh - 50px)",
    top: "50px",
    justifyContent: "flex-start",
    // background: 'red',
    background: "#fffeff",
    position: "absolute",
    zIndex: "1",
    boxShadow: "0px 3px 10px 1px rgba(0,0,0,0.75)",
    transition: "width 0.25s ease",
  },
  closedDrawer: {
    position: "absolute",
    width: "0px",
    height: "calc(100vh - 50px)",
    top: "50px",
    // background: 'red',
    zIndex: "-1",
    transition: "all 0.25s",
  },
  drawerContent: {
    marginTop: "20px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    background: "#fff",
    color: "#111",
    // height: '100vh',
    paddingTop: "20px",
    paddingLeft: "20px",
    width: "100%",
    // padding: '20px',
  },
  centersSelect: {
    width: "100%",
    height: "60px",
    display: "flex",
    flexDirection: "row",
  },
  statusSelect: {
    width: "100%",
    height: "60px",
    display: "flex",
    flexDirection: "row",
  },
  graphs: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    gap: "20px",
    width: "100%",
    height: "100%",
    paddingBlock: "20px",
  },
  graph: {
    width: "400px",
    height: "400px",
    background: "#fff",
    color: "#111",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
  },
  logoutWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    marginRight: "20px",
  },
  statusWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // justifyContent: 'space-between',
  },
  statusObjAdmin: (status) => ({
    borderRadius: "6px",
    width: "12px",
    height: "12px",
    background:
      status === "Failed" ? "red" : status === "Finished" ? "green" : "#528398",
    marginRight: "4px",
  }),
  statusObj: (status) => ({
    borderRadius: "6px",
    width: "12px",
    height: "12px",
    background:
      status === "Failed"
        ? "red"
        : status === "completed"
        ? "green"
        : "#528398",
    marginRight: "4px",
  }),
  smallWidth: {
    maxWidth: "60px",
  },
  tableHeaderItem: {
    // color: '#049c77',
    textAlign: "center",
    verticalAlign: "middle",
    color: "#fff",
    border: "none",
    padding: "5px",
  },
  table: {
    // borderRadius: '20px important!',
    border: "1px solid #111",
    // color: 'black',
    background: "#fff",
    color: "#111",
    margin: "10px",
    fontWeight: "600",
    width: "95%",
    overflow: "scroll",
    boxShadow: "0px 0px 10px 0px #000000",
  },
  tableRow: (status, findings) => {
    return {
      background:
        status === "Finished"
          ? findings === true
            ? "#ffcccb"
            : "#aaeecc"
          : "#E0E0E0",
      color: "#111",
      fontWeight: "600",
      height: "30px",
      borderTop: "1px solid #0a0a12",
      // borderRadius: '5px',
    };
  },
  tableBodyItem: {
    textAlign: "center",
    verticalAlign: "middle",
    maxHeight: "30px",
    borderWidth: "0px",
    borderStyle: "none",
    padding: "3px",
  },
  modal: {
    background: "white",
    borderRadius: "5px",
    width: "600px",
    height: "400px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  sequencesModal: {
    background: "white",
    borderRadius: "5px",
    width: "700px",
    height: "750px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    justifyContent: "space-around",
    alignItems: "center",
  },
  sequencesModalRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "95%",
  },
  select: {
    width: "400px",
    height: "40px",
    fontSize: "20px",
    fontWeight: "700",
    background: "#fff",
    color: "#000",
  },
  deleteModal: {
    background: "white",
    borderRadius: "5px",
    width: "450px",
    height: "300px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  modalBodyItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  modalButton: {
    background: "#049c77",
    color: "white",
    width: "200px",
    height: "40px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  deleteJobIcon: {
    color: "red",
    cursor: "pointer",
  },
  feedbackModal: {
    background: "white",
    borderRadius: "5px",
    width: "600px",
    height: "400px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  feedback: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderRadius: "10px",
    padding: "10px",
    background: "#eeffee",
    marginBottom: "2px",
    marginTop: "2px",
  },
  feedbackFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  popper: {
    background: "white",
    borderRadius: "5px",
    padding: "10px",
  },
  reportModal: {
    background: "white",
    borderRadius: "5px",
    width: "400px",
    height: "300px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
  },
  reportTableHeaderItem: {
    textAlign: "center",
    verticalAlign: "middle",
    color: "#000",
  },
  reportTableBodyItem: {
    textAlign: "center",
    verticalAlign: "middle",
    maxHeight: "30px",
  },
  filterRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  filtersWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "40%",
  },
  filterDate: {
    marginRight: "10px",
    marginLeft: "10px",
    border: "1px solid #000",
    borderRadius: "5px",
    padding: "5px",
    minWidth: "150px",
  },

  // entries...
  entries: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px",
    // background: 'green',
  },
  entry: (status, findings) => ({
    minHeight: "100px",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    width: "100%",
    position: "relative",
    background:
      status === "Finished"
        ? findings === true
          ? "#ffcccb"
          : "#aaeecc"
        : "#E0E0E0",
    borderTop: "1px solid #888",
  }),
  entryRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    margin: "5px",
  },
  entryRowKey: {
    fontSize: "12pt",
    fontWeight: "700",
    color: "#000",
  },
  entryRowValue: {
    fontSize: "12pt",
    fontWeight: "400",
    color: "#000",
  },
  expandButtonWrapper: {
    position: "absolute",
    left: "10px",
    bottom: "2px",
    padding: "10px",
    backgroundColor: "#1b4b84",
    color: "white",
    fontSize: "12px",
    cursor: "pointer",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
  },
  dropdownContent: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    backgroundColor: "white",
    minWidth: "220px",
    boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
    zIndex: "1",
    borderRadius: "5px",
    border: "2px solid #1b4b84",
    padding: "10px",
    // transform: 'translateX(-50%)',
    right: "5px",
    height: "fit-content",
  },
  dropdownContentItem: {
    padding: "5px",
    cursor: "pointer",
    color: "#000",
    "&:hover": {
      background: "#1b4b84",
      color: "white",
    },
    display: "flex",
    alignItems: "center",
    margin: "5px",
    fontWeight: "100",
    fontSize: "1rem",
  },
  metricsWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

const Feedback = (props) => {
  return (
    <div style={styles.feedback}>
      <p style={{ fontSize: "12pt", fontWeight: "600" }}>
        {props.index}. {props.feedback}
      </p>
      <div style={styles.feedbackFooter}>
        <p style={{ fontSize: "10pt" }}>{props.feedbackBy}</p>
        <p style={{ fontSize: "10pt" }}>
          {new Date(props.feedbackDate).toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
            hour12: false,
          })}
        </p>
      </div>
    </div>
  );
};

const Status = (props) => {
  return (
    <div style={styles.statusWrapper}>
      <div style={props.statusObj(props.status)}></div>
      {props.status}
    </div>
  );
};

const Header = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [LogoimgSrc, setLogoimgSrc] = useState("");

  React.useEffect(() => {
    setDropdownOpen(props.dropdownOpen);
  }, [props.dropdownOpen]);

  React.useEffect(() => {
    let srcX = createImageFromInitials(500, props.user, getRandomColor());
    setLogoimgSrc(srcX);
  }, []);

  return (
    <div style={styles.header}>
      <div style={styles.dashboardWrapper}>
        <div
          style={styles.menuButtonWrapper}
          className="menuButtonWrapper"
          onClick={props.toggleDrawer}
        >
          {props.admin ? (
            <MenuIcon
              style={styles.menuButton}
              onClick={(e) => {
                e.stopPropagation();
                // open drawer
                props.toggleDrawer();
              }}
            />
          ) : null}
        </div>
      </div>
      <div
        style={styles.logoutWrapper}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div style={styles.dropdown}>
          <div style={styles.dropdownButton}>
            <ProfileIcon
              onClick={() => {
                props.toggleDropdown();
              }}
              style={{
                fill: "#fff",
                width: "40px",
                height: "40px",
                cursor: "pointer",
              }}
            />
          </div>
          <div
            className={dropdownOpen ? "mounted" : "unmounted"}
            style={styles.dropdownContent}
          >
            <p
              style={{
                ...styles.dropdownContentItem,
                cursor: "default",
                borderBlockEnd: "2px solid #0087ca",
                fontWeight: "500",
              }}
            >
              <img
                id="preview"
                className="userLogo"
                src={LogoimgSrc}
                alt="profile-pic"
              />
              {props.user}
            </p>
            <p
              className="dropActionlnk"
              style={styles.dropdownContentItem}
              onClick={props.logout}
            >
              <span className="userLogo">
                <PowerSettingsNewIcon />
              </span>
              Logout
            </p>
            <p
              className="dropActionlnk"
              style={styles.dropdownContentItem}
              onClick={props.resetPassword}
            >
              <span className="userLogo">
                <LockResetIcon />
              </span>
              Reset Password
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
const Report = (props) => {
  if (
    props.admin &&
    props.center !== "kauvery" &&
    props.center !== "nirman" &&
    props.center !== "aarthi" &&
    props.center !== "mediscan" &&
    props.center !== "gic" &&
    props.center !== "ozone" &&
    props.center !== "test2" &&
    props.center !== "neoshealthtech" &&
    props.center !== "5c" &&
    props.center !== "jiva-cropped" &&
    props.center !== "ths" &&
    props.center !== "mudra" &&
    props.center !== "deeptek" &&
    props.center !== "ayucare" &&
    props.center !== "lifeline"
  ) {
    return (
      <td style={styles.tableBodyItem}>
        <SaveIcon
          style={{
            fill: "#1b4b84",
            cursor: "pointer",
          }}
          onClick={props.onSaveClick}
        />
        <Link to={`/report/${props.job.jobId}`}>
          <EditIcon
            style={{
              fill: "#1b4b84",
            }}
          />
        </Link>
      </td>
    );
  } else if (props.admin) {
    return (
      <td style={styles.tableBodyItem}>
        <SummarizeIcon
          style={{
            fill: "#1b4b84",
            cursor: "pointer",
          }}
          onClick={props.onSummarizeClick}
        />
      </td>
    );
  } else {
    if (props.center !== "nirman") {
      return (
        <td style={styles.tableBodyItem}>
          {props.job.reportStatus === "completed" ? (
            <SaveIcon
              style={{
                fill: "#1b4b84",
                cursor: "pointer",
              }}
              onClick={props.onSaveClick}
            />
          ) : null}
        </td>
      );
    } else {
      return (
        <td style={styles.tableBodyItem}>
          {props.job.reportStatus === "completed" ? (
            <div>
              <SaveIcon
                style={{
                  fill: "#1b4b84",
                  cursor: "pointer",
                }}
                onClick={props.onSaveClick}
              />
              <Link to={`/report/${props.job.jobId}`}>
                <EditIcon
                  style={{
                    fill: "#1b4b84",
                  }}
                />
              </Link>
            </div>
          ) : null}
        </td>
      );
    }
  }
};

const Entry = (props) => {
  const [expanded, setExpanded] = React.useState(false);
  React.useEffect(() => {
    setExpanded(props.expanded);
  }, [props.expanded]);
  console.log("entry: ", props.job);
  if (expanded) {
    console.log("expanded: ", props.job);
    return (
      <div style={styles.entry(props.job.status, props.job.findings)}>
        <div style={styles.entryRow}>
          <div style={styles.entryRowKey}>Job ID</div>
          <div style={styles.entryRowValue} onClick={() => {}}>
            {props.job.jobId.substring(0, 8)}
          </div>
        </div>
        <div style={styles.entryRow}>
          <div style={styles.entryRowKey}>From</div>
          <div style={styles.entryRowValue}>{props.job.from}</div>
        </div>
        <div style={styles.entryRow}>
          <div style={styles.entryRowKey}>Patient ID</div>
          <div style={styles.entryRowValue}>{props.job.patientID}</div>
        </div>
        <div style={styles.entryRow}>
          <div style={styles.entryRowKey}>Patient Name</div>
          <div style={styles.entryRowValue}>
            {props.job.patientName.substring(0, 20)}
          </div>
        </div>
        <div style={styles.entryRow}>
          <div style={styles.entryRowKey}>Status</div>
          <div style={styles.entryRowValue}>
            <Status status={props.job.status} statusObj={props.statusObj} />
          </div>
        </div>
        <div style={styles.entryRow}>
          <div style={styles.entryRowKey}>Received At</div>
          <div style={styles.entryRowValue}>
            {new Date(props.job.receivedAt).toLocaleString("en-IN", {
              timeZone: "Asia/Kolkata",
              hour12: false,
            })}
          </div>
        </div>
        <div style={styles.entryRow}>
          <div style={styles.entryRowKey}>Completed At</div>
          <div style={styles.entryRowValue}>
            {props.job.completedAt
              ? new Date(props.job.completedAt).toLocaleString("en-IN", {
                  timeZone: "Asia/Kolkata",
                  hour12: false,
                })
              : ""}
          </div>
        </div>

        <div
          style={styles.expandButtonWrapper}
          onClick={() => setExpanded(false)}
        >
          <ExpandLessIcon
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div style={styles.entry(props.job.status, props.job.findings)}>
        <div style={styles.entryRow}>
          <div style={styles.entryRowKey}>Job ID</div>
          <div style={styles.entryRowValue}>
            {props.job.jobId.substring(0, 8)}
          </div>
        </div>
        <div style={styles.entryRow}>
          <div style={styles.entryRowKey}>Patient ID</div>
          <div style={styles.entryRowValue}>{props.job.patientID}</div>
        </div>
        <div
          style={styles.expandButtonWrapper}
          onClick={() => setExpanded(true)}
        >
          <ExpandMoreIcon
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </div>
      </div>
    );
  }
};

const Entries = (props) => {
  // console.log('entries', props);
  // 10 false
  const [expanded, setExpanded] = React.useState(Array(10).fill(false));
  console.log("jobs", props.jobs);
  return (
    <div style={styles.entries}>
      {props.jobs.map((job, index) => (
        <Entry
          key={index}
          job={job}
          statusObj={props.statusObj}
          expanded={expanded[index]}
        />
      ))}
    </div>
  );
};

const Metrics = (props) => {
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [centersSelected, setCentersSelected] = React.useState([]);
  const [metricsUpdated, setMetricsUpdated] = React.useState(0);
  const [metricsOf, setMetricsOf] = React.useState("no_stent_recall");
  const [metrics, setMetrics] = React.useState({
    no_stent_recall: "",
    organ_wise_recall: "",
    staghorn_cm: "",
    staghorn_rps: "",
    with_stent_recall: "",
  });
  const [caseWiseList, setCaseWiseList] = React.useState([]);
  const [stoneWiseList, setStoneWiseList] = React.useState([]);

  React.useEffect(() => {
    setStartDate(props.startDate);
    setEndDate(props.endDate);
    setCentersSelected(props.centersSelected);
    // get metrics sheet
    updateMetrics();
  }, []);
  const updateMetrics = () => {
    let centers;
    if (centersSelected.length === 0 || centersSelected[0] === "all") {
      centers = "";
    } else {
      centers = centersSelected.join(",");
    }
    props.setLoading(true);
    for (let analyticsAPI of Object.values(props.analyticsAPIs)) {
      axios
        .get(
          `${analyticsAPI}/recall_precision?start_date=${startDate}&end_date=${endDate}&centers=${centers}`,
          {
            headers: {
              Authorization: `Bearer ${props.token}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          setMetrics(res.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          props.setLoading(false);
        });
    }
  };

  const downloadSheet = () => {
    let csvContent = "data:text/csv;charset=utf-8,";
    if (metricsOf === "Findings") {
      // extract values from caseWiseList and create csv
      // use unique_id, center, received_at, stone_findings, stone_findings_verified
      csvContent +=
        "unique_id,center,received_at,stone_findings,stone_findings_verified,left_kidney_stones,left_kidney_stones_verified,right_kidney_stones,right_kidney_stones_verified,left_ureter_stones,left_ureter_stones_verified,right_ureter_stones,right_ureter_stones_verified,bladder_stones,bladder_stones_verified\n";
      for (let i = 0; i < caseWiseList.length; i++) {
        csvContent += `${caseWiseList[i].unique_id},${
          caseWiseList[i].center
        },${caseWiseList[i].received_at.replace(",", " ")},${
          caseWiseList[i].stone_findings
        },${caseWiseList[i].stone_findings_verified},${
          caseWiseList[i].left_kidney_stones
        },${caseWiseList[i].left_kidney_stones_verified},${
          caseWiseList[i].right_kidney_stones
        },${caseWiseList[i].right_kidney_stones_verified},${
          caseWiseList[i].left_ureter_stones
        },${caseWiseList[i].left_ureter_stones_verified},${
          caseWiseList[i].right_ureter_stones
        },${caseWiseList[i].right_ureter_stones_verified},${
          caseWiseList[i].bladder_stones
        },${caseWiseList[i].bladder_stones_verified}\n`;
      }
    } else if (metricsOf === "PFS") {
      // use unique_id, center, received_at, left_pfs, left_pfs_verified, right_pfs, right_pfs_verified
      csvContent +=
        "unique_id,center,received_at,left_pfs,left_pfs_verified,right_pfs,right_pfs_verified\n";
      for (let i = 0; i < caseWiseList.length; i++) {
        csvContent += `${caseWiseList[i].unique_id},${
          caseWiseList[i].center
        },${caseWiseList[i].received_at.replace(",", " ")},${
          caseWiseList[i].left_pfs
        },${caseWiseList[i].left_pfs_verified},${caseWiseList[i].right_pfs},${
          caseWiseList[i].right_pfs_verified
        }\n`;
      }
    } else if (metricsOf === "HN") {
      // use unique_id, center, received_at, left_kidney_hun_hn, left_kidney_hun_hn_verified, right_kidney_hun_hn, right_kidney_hun_hn_verified
      csvContent +=
        "unique_id,center,received_at,left_kidney_hun_hn,left_kidney_hun_hn_verified,right_kidney_hun_hn,right_kidney_hun_hn_verified\n";
      for (let i = 0; i < caseWiseList.length; i++) {
        csvContent += `${caseWiseList[i].unique_id},${
          caseWiseList[i].center
        },${caseWiseList[i].received_at.replace(",", " ")},${
          caseWiseList[i].left_kidney_hun_hn
        },${caseWiseList[i].left_kidney_hun_hn_verified},${
          caseWiseList[i].right_kidney_hun_hn
        },${caseWiseList[i].right_kidney_hun_hn_verified}\n`;
      }
    } else if (metricsOf === "Calculi") {
      // use stone wise list
      // use unique_id, center, received_at, stone_side, stone_size, stone_volume, stone_staghorn, stone_outcome
      csvContent +=
        "unique_id,center,received_at,stone_side,stone_size,stone_volume,stone_staghorn,stone_outcome\n";
      for (let i = 0; i < stoneWiseList.length; i++) {
        csvContent += `${stoneWiseList[i].unique_id},${
          stoneWiseList[i].center
        },${stoneWiseList[i].received_at.replace(",", " ")},${
          stoneWiseList[i].stone_side
        },${stoneWiseList[i].stone_size},${stoneWiseList[i].stone_volume},${
          stoneWiseList[i].stone_staghorn
        },${stoneWiseList[i].stone_outcome}\n`;
      }
    }
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${metricsOf}_metrics.csv`);
    document.body.appendChild(link); // Required for FF
    link.click();

    document.body.removeChild(link);
  };

  React.useEffect(() => {
    updateMetrics();
  }, [metricsUpdated]);
  React.useEffect(() => {
    setMetricsUpdated(props.metricsUpdated);
  }, [props.metricsUpdated]);
  React.useEffect(() => {
    console.log("startDate useEffect", props.startDate);
    setStartDate(props.startDate);
  }, [props.startDate]);
  React.useEffect(() => {
    setEndDate(props.endDate);
  }, [props.endDate]);
  React.useEffect(() => {
    setCentersSelected(props.centersSelected);
  }, [props.centersSelected]);

  return (
    <div style={styles.metricsWrapper}>
      <Select
        styles={{
          width: "200px",
          height: "40px",
          fontSize: "20px",
          fontWeight: "700",
          background: "#fff",
          marginBottom: "20px",
        }}
        value={metricsOf}
        onChange={(e) => {
          setMetricsOf(e.target.value);
        }}
      >
        {Object.keys(metrics).map((key, index) => (
          <MenuItem key={index} value={key}>
            {key}
          </MenuItem>
        ))}
      </Select>
      {metrics && (
        <div>
          {(() => {
            switch (metricsOf) {
              case "no_stent_recall":
                return <FindingsMetrics metrics={metrics[metricsOf]} />;
              case "organ_wise_recall":
                return <PFSMetrics metrics={metrics[metricsOf]} />;
              // case "with_stent_recall":
              //   return <HNMetrics metrics={metrics[metricsOf]} />;
              case "staghorn_cm":
                return <CalculiMetrics metrics={metrics[metricsOf]} />;
              case "staghorn_rps":
                return <CalculiMetrics metrics={metrics[metricsOf]} />;
              case "with_stent_recall":
                return <CalculiMetrics metrics={metrics[metricsOf]} />;
              // default: return null;
            }
          })()}
          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Button variant="contained" color="primary" onClick={downloadSheet}>
              Download Sheet
            </Button>
          </div> */}
        </div>
      )}
    </div>
  );
};

const CasesTable = (props) => {
  console.log("cases table", props);
  return (
    <div style={{ height: "100%" }}>
      <Table style={styles.table} id="jobsTable">
        <TableHead>
          <TableRow style={{ background: "#1b4b84" }}>
            <td style={styles.tableHeaderItem}>Center</td>
            <th style={styles.tableHeaderItem}>Job ID</th>
            <th style={styles.tableHeaderItem}>From</th>
            <th style={styles.tableHeaderItem}>Patient ID</th>
            <th style={styles.tableHeaderItem}>Patient Name</th>
            <th style={styles.tableHeaderItem}>Status</th>
            <th style={styles.tableHeaderItem}>Recieved At</th>
            <th style={styles.tableHeaderItem}>Completed At</th>
            <th style={styles.tableHeaderItem}>Link</th>
          </TableRow>
        </TableHead>

        <TableBody>
          {props.jobs.map((job) => (
            <tr
              key={job.jobId}
              style={styles.tableRow(job.status, job.findings)}
            >
              <td style={styles.tableBodyItem}>{job.center}</td>
              {/* <td style={styles.tableBodyItem}>{job.jobId}</td> */}
              <td
                style={styles.tableBodyItem}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                  e.target.title = job.jobId;
                }}
                onClick={(e) => {
                  props.onJobIdClick(e.target, job.jobId);
                }}
              >
                {/* // show only 8 characters of job id */}
                {job.jobId.substring(0, 8)}
              </td>
              <td style={styles.tableBodyItem}>{job.dataFrom}</td>
              <td style={styles.tableBodyItem}>{job.patientId}</td>
              <td style={styles.tableBodyItem}>{job.patientName}</td>
              <td style={styles.tableBodyItem}>
                {props.admin ? (
                  <Status
                    status={job.status}
                    statusObj={styles.statusObjAdmin}
                  />
                ) : (
                  <Status
                    status={
                      job.reportStatus ||
                      (job.status.toLowerCase() === "queued" && job.status) ||
                      (job.status.toLowerCase() === "failed" && job.status) ||
                      "Processing"
                    }
                    statusObj={styles.statusObj}
                  />
                )}
              </td>
              <td style={styles.tableBodyItem}>
                {new Date(job.receivedAt).toLocaleString("en-IN", {
                  timeZone: "Asia/Kolkata",
                  hour12: false,
                })}
              </td>
              <td style={styles.tableBodyItem}>
                {props.admin
                  ? job.completedAt === null
                    ? "NA"
                    : new Date(job.completedAt).toLocaleString("en-IN", {
                        timeZone: "Asia/Kolkata",
                        hour12: false,
                      })
                  : job.verfiedAt === null
                  ? "NA"
                  : new Date(job.verfiedAt).toLocaleString("en-IN", {
                      timeZone: "Asia/Kolkata",
                      hour12: false,
                    })}
              </td>
              <td style={styles.tableBodyItem}>
                <OpenInNewIcon
                  style={{ color: "#1b4b84", cursor: "pointer" }}
                  onClick={() => {
                    props.onCaseClick(job);
                  }}
                />
              </td>
            </tr>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default function Analytics(props) {
  // get navigate state

  const location = useLocation();
  console.log("location", location);
  const locationQuery = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const [jobs, setJobs] = useState([]);
  const [jobUpdated, setJobUpdated] = useState(0);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [jobId, setJobId] = useState("");
  const [newFeedback, setNewFeedback] = useState("");
  const [feedbacks, setFeedbacks] = useState([]);
  const [page, setPage] = useState(
    (locationQuery.get("page") && parseInt(locationQuery.get("page"))) || 0
  );
  const [filterText, setFilterText] = useState(
    locationQuery.get("filterText") || ""
  );
  const [startDate, setStartDate] = useState(
    locationQuery.get("startDate") || ""
  );
  const [endDate, setEndDate] = useState(locationQuery.get("endDate") || "");

  const [loading, setLoading] = useState(false);

  const [toastMessage, setToastMessage] = useState("");
  const [toastOpen, setToastOpen] = useState(false);
  const [toastAnchorEl, setToastAnchorEl] = useState(null);
  const [toastPlacement, setToastPlacement] = useState("");

  const [sequencesModalOpen, setSequencesModalOpen] = useState(false);

  const [metadata, setMetadata] = React.useState({});
  const [sequence, setSequence] = React.useState("");
  const [selectedSequence, setSelectedSequence] = React.useState("");
  const [imgSrc, setImgSrc] = React.useState("");

  const [baseURL, setBaseURL] = useState("");
  const [baseURLAPI, setBaseURLAPI] = useState("");
  const [ohifURL, setOhifURL] = useState("");
  const [center, setCenter] = useState("test_5");
  const [admin, setAdmin] = useState(false);

  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const [mobile, setMobile] = useState(false);

  const [loggedIn, setLoggedIn] = useState(true);

  const [analyticsOpen, setAnalyticsOpen] = useState(true);
  const [jobsAnalytics, setJobsAnalytics] = useState({});

  const [showMetrics, setShowMetrics] = useState(false);
  const [metricsUpdated, setMetricsUpdated] = useState(0);

  const [centersSelected, setCentersSelected] = useState(["all"]);
  const [statusSelected, setStatusSelected] = useState("");

  const [reloadInterval, setReloadInterval] = useState(null);

  const [retry, setRetry] = useState(0);

  const statuses = ["Queued", "Processing", "Finished", "Approved", "Failed"];

  const [advancedFilterOpen, setAdvancedFilterOpen] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState({
    staghorn: "Any",
    stoneVolume: "Any",
    ureter: "Any",
    hun: "Any",
    verified: "Any",
    combination: "And",
  });

  console.log(
    location,
    locationQuery.get("page"),
    locationQuery.get("page") || 1,
    page,
    "location"
  );

  React.useEffect(() => {
    console.log("getting center and admin");
    // get center from local storage
    // const center = localStorage.getItem('center');
    // const center = "medimax";
    const center = "test_5";
    const admin = localStorage.getItem("admin");

    setCenter(center);
    setAdmin(admin === "true");

    if (!center || !admin) {
      setLoggedIn(false);
    }

    console.log(center, admin, "center and admin");

    setBaseURL(inferURLs[center]);
    setBaseURLAPI(apiURLs[center]);
    setOhifURL(ohifURLs[center]);

    window.addEventListener("resize", () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    });

    // close dropdown on click outside
    document.body.addEventListener("click", (e) => {
      console.log(e.target, !e.target.id, "e.target.id", "click");
      if (!e.target.id || e.target.id !== "profileDropdown") {
        console.log("setting profile dropdown to false");
        setProfileDropdownOpen(false);
      }
    });
  }, []);

  React.useEffect(() => {
    // on change dimensions, check if width is less than 600px
    if (dimensions.width < 600) {
      // if yes remove table columns and allow them to be expanded
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [dimensions]);

  React.useEffect(() => {
    if (analyticsOpen) {
      // remove reload interval
      clearInterval(reloadInterval);
    } else {
      // set reload interval
      const interval = setInterval(() => {
        setRetry((retry) => retry + 1);
      }, 15000);
      setReloadInterval(interval);
    }
  }, [analyticsOpen]);

  const onGetJobsError = (err) => {
    if (admin) {
      // just display the error message and ask to login again
      console.log(err, "err");
      setToastMessage(err.message + ". Please login again.");
      setToastOpen(true);
      setToastAnchorEl(document.getElementById("jobsTable"));
      setToastPlacement("bottom");
      setTimeout(() => {
        setToastOpen(false);
        setToastPlacement("");
      }, 3000);
    } else {
      // if not admin, then the token has expired
      // redirect to login page
      setLoggedIn(false);
    }
  };

  const getJobs = () => {
    console.log("getting jobs");
    setLoading(true);
    setJobs([]);
    const token = localStorage.getItem(`token-${"aarthi"}`);
    let centers;
    if (centersSelected.length === 0 || centersSelected[0] === "all") {
      centers = "";
    } else {
      centers = centersSelected.join(",");
    }
    setJobs([]);
    for (let analyticsAPI of Object.values(analyticsAPIs)) {
      axios
        .get(
          `${analyticsAPI}/jobs_by_status?status=${statusSelected}&start_date=${startDate}&end_date=${endDate}&centers=${centers}&page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setJobs((prevJobs) => [...prevJobs, ...res.data.jobs]);
        })
        .catch((err) => {
          onGetJobsError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  React.useEffect(() => {
    if (baseURL !== "" && !analyticsOpen) {
      if (showMetrics) return;
      getJobs();
    }
  }, [jobUpdated, page, baseURL, startDate, endDate, analyticsOpen, retry]);

  React.useEffect(() => {
    if (baseURL !== "" && analyticsOpen) {
      getAnalytics();
    }
  }, [baseURL, analyticsOpen]);

  const handleCenterChange = (center) => {
    console.log(center, "center", inferURLs[center], apiURLs[center]);
    setCenter(center);
    setBaseURL(inferURLs[center]);
    setBaseURLAPI(apiURLs[center]);
    setOhifURL(ohifURLs[center]);
    localStorage.setItem("center", center);
  };

  React.useEffect(() => {
    console.log("jobs analytics", jobsAnalytics);
    if (
      Object.keys(jobsAnalytics).length === Object.keys(analyticsAPIs).length
    ) {
      let data = [];
      let keys = [];
      const allServerCounts = Object.values(jobsAnalytics).map(
        (server) => server.counts
      );
      console.log(allServerCounts, "counts");
      for (let serverCounts of allServerCounts) {
        // push unique keys
        for (let key of Object.keys(serverCounts)) {
          if (!keys.includes(key)) {
            keys.push(key);
            data.push({
              key,
              value: serverCounts[key],
            });
          } else {
            data[keys.indexOf(key)].value += serverCounts[key];
          }
        }
      }
      console.log(data, "data");
      // filter out keys with value 0
      data = data.filter((d) => d.value !== 0);
      // map keys to colors
      const colorsMap = {
        Queued: "#FFC107",
        Approved: "#4CAF50",
        Finished: "#2196F3",
        Failed: "#F44336",
        Processing: "#FF9800",
      };

      d3.select("#pieChart").selectAll("*").remove();
      const pie = d3.pie().value((d) => d.value);
      const arc = d3.arc().innerRadius(0).outerRadius(100);
      const svgPie = d3
        .select("#pieChart")
        .append("svg")
        .attr("width", 400)
        .attr("height", 400);
      const g = svgPie.append("g").attr("transform", "translate(200, 200)");
      const arcs = g
        .selectAll("arc")
        .data(pie(data))
        .enter()
        .append("g")
        .attr("class", "arc");
      arcs
        .append("path")
        .attr("d", arc)
        .attr("fill", (d, i) => {
          return colorsMap[d.data.key];
        });

      // add legend
      const legendPie = svgPie
        .append("g")
        .attr("transform", "translate(200, 0)");

      const legendItems = legendPie
        .selectAll("g")
        .data(data)
        .enter()
        .append("g")
        .attr("transform", (d, i) => {
          return `translate(50, ${i * 20 + 10})`;
        });

      legendItems
        .append("rect")
        .attr("width", 10)
        .attr("height", 10)
        .attr("fill", (d, i) => {
          return colorsMap[d.key];
        });

      legendItems
        .append("text")
        .attr("x", 20)
        .attr("y", 10)
        .text((d) => d.key);

      // hover and click
      arcs.on("mouseover", function (e, d) {
        // make darker
        // console.log('d', d, i);
        d3.select(this)
          .select("path")
          .attr("fill", d3.rgb(colorsMap[d.data.key]).darker());
        // show value
        d3.select(this)
          .append("text")
          .attr("class", "value")
          .attr("transform", (d) => {
            const centroid = arc.centroid(d);
            return `translate(${centroid[0]}, ${centroid[1]})`;
          })
          .attr("text-anchor", "middle")
          .text((d) => d.data.value);
      });
      arcs.on("mouseout", function (e, d) {
        // make lighter
        d3.select(this).select("path").attr("fill", colorsMap[d.data.key]);
        // remove value
        d3.select(this).select(".value").remove();
      });
      arcs.on("click", function (e, d) {
        console.log("hellooo");
        // close all charts
        d3.select("#pieChart").selectAll("*").remove();
        d3.select("#barChart").selectAll("*").remove();
        d3.select("#lineChart").selectAll("*").remove();
        setStatusSelected(d.data.key);
        setShowMetrics(false);
        setAnalyticsOpen(false);
      });
      arcs.style("cursor", "pointer");

      // bar chart for timings
      d3.select("#barChart").selectAll("*").remove();
      const barData = Object.values(jobsAnalytics).map(
        (server) => server.timings_summarized
      );
      data = [];
      for (let serverData of barData) {
        for (let key of Object.keys(serverData)) {
          data.push({
            key,
            value: serverData[key],
          });
        }
      }
      console.log(data, "bar data");
      const barColors = ["#FFC107", "#4CAF50", "#2196F3", "#F44336", "#FF9800"];
      const margin = { top: 20, right: 20, bottom: 30, left: 40 };
      const width = 400 - margin.left - margin.right;
      const height = 400 - margin.top - margin.bottom;
      const x = d3.scaleBand().rangeRound([0, width]).padding(0.1);
      const y = d3.scaleLinear().rangeRound([height, 0]);
      const xAxis = d3.axisBottom(x);
      const yAxis = d3.axisLeft(y).ticks(10);
      const svgBar = d3
        .select("#barChart")
        .append("svg")
        .attr("width", 400)
        .attr("height", 400);
      const gBar = svgBar
        .append("g")
        .attr("transform", `translate(${margin.left}, ${margin.top})`);
      x.domain(data.map((d) => d.key));
      y.domain([0, d3.max(data, (d) => d.value)]);
      gBar.append("g").attr("transform", `translate(0, ${height})`).call(xAxis);
      gBar
        .append("g")
        .call(yAxis)
        .append("text")
        .attr("fill", "#000")
        .attr("y", -20)
        .attr("x", 5)
        .attr("dy", "0.71em")
        .attr("text-anchor", "end")
        .text("Time (s)");
      gBar
        .selectAll(".bar")
        .data(data)
        .enter()
        .append("rect")
        .attr("class", "bar")
        .attr("x", (d) => x(d.key))
        .attr("y", (d) => y(d.value))
        .attr("width", x.bandwidth())
        .attr("height", (d) => height - y(d.value))
        .attr("fill", (d, i) => barColors[i % barColors.length]);

      // line chart for timings
      d3.select("#lineChart").selectAll("*").remove();
      const lineData = Object.values(jobsAnalytics).map(
        (server) => server.timings
      );
      data = [];
      for (let serverData of lineData) {
        // for (let key of Object.keys(serverData)) {
        //     data.push({
        //         key,
        //         value: serverData[key],
        //     });
        // }
        let values = Object.values(serverData);
        let keys = Object.keys(serverData);
        console.log("keys", keys, values);
        for (let i = 0; i < values[0].length; i++) {
          let data_i = { key: i };
          for (let j = 0; j < keys.length; j++) {
            data_i[keys[j]] = values[j][i];
          }
          data.push(data_i);
        }
      }

      const marginLine = { top: 10, right: 30, bottom: 30, left: 60 };
      const widthLine = 460 - margin.left - margin.right;
      const heightLine = 400 - margin.top - margin.bottom;

      // Create the SVG element
      const svgLine = d3
        .select("#lineChart")
        .append("svg")
        .attr("width", widthLine + marginLine.left + marginLine.right)
        .attr("height", heightLine + marginLine.top + marginLine.bottom)
        .append("g")
        .attr(
          "transform",
          "translate(" + marginLine.left + "," + marginLine.top + ")"
        );

      // Define the x scale
      const xLine = d3
        .scaleLinear()
        .range([0, width])
        .domain([0, data.length - 1]);

      // Define the y scale
      const yLine = d3
        .scaleLinear()
        .range([height, 0])
        .domain([0, d3.max(data, (d) => d3.max(Object.values(d)))]);

      // Define the color scale
      const color = d3.scaleOrdinal(d3.schemeCategory10);

      // Add the x-axis
      svgLine.append("g").attr("transform", "translate(0," + height + ")");
      //   .call(d3.axisBottom(x).ticks(data.length));

      // Add the y-axis
      svgLine.append("g").call(d3.axisLeft(y));

      // add time text
      svgLine
        .append("text")
        .attr("fill", "#000")
        .attr("y", -10)
        .attr("x", 5)
        .attr("dy", "0.71em")
        .attr("text-anchor", "end")
        .style("font-size", "12px")
        .text("Time (s)");

      for (let key of Object.keys(data[0])) {
        console.log("key", key);
        if (["key", "jobId", "center"].includes(key)) continue;
        console.log("key", key);
        svgLine
          .append("path")
          .datum(data)
          .attr("fill", "none")
          .attr("stroke", color(key))
          .attr("stroke-width", 1.5)
          .attr(
            "d",
            d3
              .line()
              .x((d, i) => xLine(i))
              .y((d) => yLine(d[key]))
          );
      }

      // Add the legend
      const legendLine = svgLine
        .append("g")
        .attr("transform", "translate(" + (width - 10) + "," + 10 + ")")
        .selectAll("g")
        .data(
          Object.keys(data[0]).filter(
            (d) => !["key", "jobId", "center"].includes(d)
          )
        )
        .enter()
        .append("g")
        .attr("transform", (d, i) => "translate(0," + i * 20 + ")");

      legendLine
        .append("rect")
        .attr("x", -18)
        .attr("width", 18)
        .attr("height", 18)
        .attr("fill", color);

      legendLine
        .append("text")
        .attr("x", -24)
        .attr("y", 9)
        .attr("dy", "0.35em")
        .attr("text-anchor", "end")
        .text((d) => d);

      // mouseover event handler for line chart, opaque tooltip
      const tooltipLine = d3
        .select("#lineChart")
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0);
      const bisect = d3.bisector((d) => d.key).left;
      const lineMouseover = (e, d) => {
        // console.log('mouseover', e, d);
        svgLine.select("#lineHover").remove();

        const x0 = xLine.invert(d3.pointer(e)[0]);
        const i = bisect(d, x0, 1);
        const d0 = d[i - 1];
        const d1 = d[i];
        const d_ = x0 - d0.key > d1.key - x0 ? d1 : d0;
        console.log("d_", d_);

        tooltipLine.style("opacity", 1);
        let html = `<div style="border: 1px solid black; padding: 5px; zIndex: 1000; background: white;"><div style="font-weight: bold; text-align: center;">${d_.key}</div>`;
        for (let key of Object.keys(d_)) {
          if (["key", "jobId", "center"].includes(key)) continue;
          html += `<div>${key}: ${d_[key]}</div>`;
        }
        html += `<div>Click to see the job</div></div>`;
        // plot on top left of the chart
        tooltipLine
          .html(html)
          .style("position", "absolute")
          .style("left", `${e.pageX - 100}px`)
          .style("top", `${e.pageY - 100}px`);
        // on click
        tooltipLine.on("click", () => {
          // navigate to the job
          localStorage.setItem("center", d_.center);
          navigate(`/jobs?filterText=${d_.jobId}`);
        });
        // draw a vertical line on the chart
        svgLine
          .append("line")
          .attr("id", "lineHover")
          .attr("x1", xLine(d_.key))
          .attr("x2", xLine(d_.key))
          .attr("y1", 0)
          .attr("y2", heightLine)
          .style("stroke", "black")
          .style("stroke-width", 1);
        // remove after 5 seconds
        setTimeout(() => {
          tooltipLine.transition().duration(200).style("opacity", 0);
          // remove on click
          tooltipLine.on("click", null);
          svgLine.select("#lineHover").remove();
        }, 2000);
      };
      const lineMouseout = (e, d) => {
        console.log("mouseout", e, d);
        tooltipLine.transition().duration(200).style("opacity", 0);
        svgLine.select("#lineHover").remove();
      };
      svgLine.selectAll("path").on("mouseover", lineMouseover);
      // d3.selectAll('svg').on('mouseout', lineMouseout);
    }
  }, [jobsAnalytics]);

  const getAnalytics = () => {
    const token = localStorage.getItem(`token-${"aarthi"}`);
    setJobsAnalytics({});
    for (let analyticsAPI of Object.values(analyticsAPIs)) {
      let centers;
      if (centersSelected.length === 0 || centersSelected[0] === "all") {
        centers = "";
      } else {
        centers = centersSelected.join(",");  
      }
      axios
        .get(
          `${analyticsAPI}/jobs?start_date=${startDate}&end_date=${endDate}&centers=${centers}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          setJobsAnalytics((jobsAnalytics) => {
            return {
              ...jobsAnalytics,
              [analyticsAPI]: res.data,
            };
          });
        })
        .catch((err) => {
          console.log(err, "error");
          const data = [
            {
              name: "Failed",
              value: 5,
            },
            {
              name: "Successful",
              value: 10,
            },
            {
              name: "Failed",
              value: 5,
            },
          ];

          d3.select("#pieChart").selectAll("*").remove();
          const pie = d3.pie().value((d) => d.value);
          const arc = d3.arc().innerRadius(0).outerRadius(100);
          const svg = d3
            .select("#pieChart")
            .append("svg")
            .attr("width", 200)
            .attr("height", 200);
          const g = svg.append("g").attr("transform", "translate(100, 100)");
          const arcs = g
            .selectAll("arc")
            .data(pie(data))
            .enter()
            .append("g")
            .attr("class", "arc");
          arcs
            .append("path")
            .attr("d", arc)
            .attr("fill", (d, i) => {
              return i === 0 ? "#ff0000" : "#00ff00";
            });
          // legend
          arcs
            .append("text")
            .attr("transform", (d) => `translate(${arc.centroid(d)})`)
            .attr("text-anchor", "middle")
            .text((d) => d.data.name);
          // hover and click
          arcs.on("mouseover", function (d, i) {
            d3.select(this)
              .select("path")
              .attr("fill", "#0000ff")
              .attr("cursor", "pointer")
              .attr("stroke", "#000000")
              .attr("stroke-width", 2);
          });
          arcs.on("mouseout", function (d, i) {
            d3.select(this)
              .select("path")
              .attr("fill", i === 0 ? "#ff0000" : "#00ff00")
              .attr("stroke", "none");
          });
          arcs.on("click", function (d, i) {
            console.log("hellooo");
            console.log(d, i, "clicked", i.data.name);
          });
        });
    }
  };

  let inputRef = null;

  if (baseURL === "") {
    return (
      // show loading
      <div style={styles.wrapper}>
        <Header
          user={localStorage.getItem("username")}
          admin={admin}
          handleCenterChange={handleCenterChange}
          logout={() => onLogout({ admin: admin, center: center })}
          center={center}
          dropdownOpen={profileDropdownOpen}
          toggleDropdown={() => setProfileDropdownOpen(!profileDropdownOpen)}
          resetPassword={() => navigate("/password_reset")}
          toggleDrawer={() => {
            console.log("toggle drawer", drawerOpen);
            setDrawerOpen(!drawerOpen);
          }}
        />
        <CircularProgress
          style={{ color: "#1b4b84", margin: "auto", marginTop: "100px" }}
        />
      </div>
    );
  }

  console.log("showMetrics", showMetrics);

  const exportJobs = (exportJson) => {
    const token = localStorage.getItem(`token-${"aarthi"}`);
    let centers;
    if (centersSelected.length === 0 || centersSelected[0] === "all") {
      centers = "";
    } else {
      centers = centersSelected.join(",");
    }
    let url = `${
      Object.values(analyticsAPIs)[0]
    }/jobs_by_status?status=${statusSelected}&start_date=${startDate}&end_date=${endDate}&centers=${centers}&export_all=true`;
    if (advancedFilterOpen) {
      url = url.replace("jobs_by_status", "jobs_advanced_filter");
      url += `&staghorn=${advancedFilters.staghorn.toLowerCase()}&stone_size=${advancedFilters.stoneVolume.toLowerCase()}&ureter=${advancedFilters.ureter.toLowerCase()}&hun=${advancedFilters.hun.toLowerCase()}&verified=${advancedFilters.verified.toLowerCase()}&filters_combine=${advancedFilters.combination.toLowerCase()}`;
    }
    if (exportJson) {
      url += "&export_json=true";
    }
    console.log("url", url);
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (exportJson) {
          let jobs = res.data.jobs;
          // download json
          var dataStr =
            "data:text/json;charset=utf-8," +
            encodeURIComponent(JSON.stringify(jobs));
          var dlAnchorElem = document.createElement("a");
          dlAnchorElem.setAttribute("href", dataStr);
          dlAnchorElem.setAttribute("download", "jobs.json");
          dlAnchorElem.click();

          dlAnchorElem.remove();
        } else {
          let jobs = res.data.jobs;
          let csv = [];
          let row = [];
          let filterColumns = ["warnings", "sentToReferring", "reportPath"];
          // create csv header
          for (let key in jobs[0]) {
            if (filterColumns.includes(key)) {
              continue;
            }
            row.push(key.replace(",", " "));
          }
          csv.push(row);
          // create csv data
          for (let i = 0; i < jobs.length; i++) {
            row = [];
            for (let key in jobs[i]) {
              if (filterColumns.includes(key)) {
                continue;
              }
              row.push(String(jobs[i][key]).replace(",", " "));
            }
            csv.push(row);
          }
          console.log(csv);
          let csvContent =
            "data:text/csv;charset=utf-8," +
            csv.map((e) => e.join(",")).join("\n");
          console.log(csvContent);
          var encodedUri = encodeURI(csvContent);
          var link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", "jobs.csv");
          document.body.appendChild(link); // Required for FF
          link.click();
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  if (!loggedIn) {
    return <Navigate to="/login" />;
  }

  console.log("jobs", jobs);

  return (
    <div style={styles.wrapper}>
      <Header
        user={localStorage.getItem("username")}
        admin={admin}
        handleCenterChange={handleCenterChange}
        logout={() => onLogout({ admin: admin, center: center })}
        center={center}
        dropdownOpen={profileDropdownOpen}
        toggleDropdown={() => setProfileDropdownOpen(!profileDropdownOpen)}
        resetPassword={() => navigate("/password_reset")}
        toggleDrawer={() => {
          console.log("toggle drawer", drawerOpen);
          setDrawerOpen(!drawerOpen);
        }}
      />
      <div style={styles.container}>
        {admin ? (
          <div style={drawerOpen ? styles.drawer : styles.closedDrawer}>
            <img
              src={logo}
              style={{ width: "100%", height: "auto", marginBottom: "20px" }}
            />
            <div style={styles.drawerContent}>
              <div
                className={
                  location.pathname === "/jobs"
                    ? "drawerItem Active"
                    : "drawerItem Inactive"
                }
                onClick={() => navigate("/jobs")}
              >
                <ListIcon style={{ marginRight: "10px" }} />
                <p
                  style={{
                    margin: "0px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Jobs
                </p>
              </div>
              <div
                className={
                  location.pathname === "/jobsall"
                    ? "drawerItem Active"
                    : "drawerItem Inactive"
                }
                onClick={() => navigate("/admin/jobsall")}
              >
                <ListIcon style={{ marginRight: "10px" }} />
                <p
                  style={{
                    margin: "0px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  All Centers Jobs
                </p>
              </div>
              <div
                className={
                  location.pathname === "/tasks"
                    ? "drawerItem Active"
                    : "drawerItem Inactive"
                }
                onClick={() => navigate("/admin/tasks")}
              >
                <ListIcon style={{ marginRight: "10px" }} />
                <p
                  style={{
                    margin: "0px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Tasks List
                </p>
              </div>
              <div
                className={
                  location.pathname === "/analytics"
                    ? "drawerItem Active"
                    : "drawerItem Inactive"
                }
                onClick={() => navigate("/analytics")}
              >
                <BarChartIcon style={{ marginRight: "10px" }} />
                <p
                  style={{
                    margin: "0px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Analytics
                </p>
              </div>
              <div
                className={
                  location.pathname === "/user-management"
                    ? "drawerItem Active"
                    : "drawerItem Inactive"
                }
                onClick={() => navigate("/admin/user-management")}
              >
                <PersonAddIcon style={{ marginRight: "10px" }} />
                <p
                  style={{
                    margin: "0px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  User Management
                </p>
              </div>
            </div>
          </div>
        ) : null}
        <div style={styles.content}>
          {!analyticsOpen && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "80%",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                onClick={() => {
                  setAnalyticsOpen(true);
                }}
              >
                <ArrowBackIcon />
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{
                  marginRight: "10px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                onClick={() => {
                  console.log("export");
                  exportJobs();
                }}
              >
                <GetAppIcon />
                Export
              </Button>
              {advancedFilterOpen && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    marginRight: "10px",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  onClick={() => {
                    console.log("export");
                    exportJobs(true);
                  }}
                >
                  <GetAppIcon />
                  Export JSON
                </Button>
              )}
            </div>
          )}
          {analyticsOpen ? (
            <Button
              variant="contained"
              color="primary"
              style={{
                marginLeft: "10px",
                marginTop: "10px",
                marginBottom: "10px",
              }}
              onClick={() => {
                setShowMetrics(true);
                setAnalyticsOpen(false);
              }}
            >
              Metrics
            </Button>
          ) : null}

          {!advancedFilterOpen ? (
            <div style={styles.filterRow}>
              {!analyticsOpen && !showMetrics ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    minWidth: "220px",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    setAdvancedFilterOpen(!advancedFilterOpen);
                  }}
                >
                  Advanced Filters
                </Button>
              ) : null}
              <p style={{ margin: 0, padding: 0, color: "#1b4b84" }}>
                Start Date
              </p>
              <input
                type="date"
                onChange={(e) => {
                  console.log(e.target.value);
                  setStartDate(e.target.value);
                  // navigate(`/analytics?showAll=${showAll}&page=${page}&&startDate=${e.target.value}&endDate=${endDate}`);
                }}
                style={styles.filterDate}
              />
              <p style={{ margin: 0, padding: 0, color: "#1b4b84" }}>
                End Date
              </p>
              <input
                type="date"
                onChange={(e) => {
                  console.log(e.target.value);
                  setEndDate(e.target.value);
                  // navigate(`/jobs?showAll=${showAll}&page=${page}&startDate=${startDate}&endDate=${e.target.value}`);
                }}
                style={styles.filterDate}
              />

              <div style={styles.centersSelect}>
                <p style={{ color: "#1b4b84" }}>Centers</p>
                <Select
                  closeMenuOnSelect={false}
                  style={{
                    width: "200px",
                    marginLeft: "10px",
                    marginRight: "10px",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  // components={animatedComponents}
                  value={centersSelected}
                  renderValue={(selected) => {
                    console.log(selected);
                    if (selected.length === 0) {
                      return <em>Select Centers</em>;
                    }
                    return selected.join(", ");
                  }}
                  multiple
                  options={Object.keys(apiURLs)}
                >
                  <MenuItem value="all">
                    <ListItemIcon>
                      <Checkbox
                        checked={
                          Object.keys(apiURLs).length === centersSelected.length
                        }
                        onChange={(e) => {
                          console.log(e, "clicked icon");
                          e.stopPropagation();
                          if (
                            centersSelected.length ===
                            Object.keys(apiURLs).length
                          ) {
                            // remove all from selected
                            setCentersSelected([]);
                          } else {
                            setCentersSelected(Object.keys(apiURLs));
                          }
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="all" />
                  </MenuItem>
                  {Object.keys(apiURLs).map((center, index) => {
                    return (
                      <MenuItem key={index} value={center}>
                        <ListItemIcon>
                          <Checkbox
                            checked={
                              centersSelected.indexOf(center) > -1 ||
                              centersSelected.indexOf("all") > -1
                            }
                            onChange={(e) => {
                              console.log(e, "clicked icon");
                              e.stopPropagation();
                              if (
                                centersSelected.length ===
                                  Object.keys(apiURLs).length ||
                                centersSelected.indexOf("all") > -1
                              ) {
                                // remove all from selected
                                let newCentersSelected = Object.keys(
                                  apiURLs
                                ).filter((c) => c !== center);
                                setCentersSelected(newCentersSelected);
                              } else {
                                // check if center is already selected
                                if (centersSelected.indexOf(center) > -1) {
                                  console.log(
                                    "center already selected, removing",
                                    center
                                  );
                                  let newCentersSelected =
                                    centersSelected.filter((c) => c !== center);
                                  setCentersSelected(newCentersSelected);
                                } // add center to selected
                                else
                                  setCentersSelected([
                                    ...centersSelected,
                                    center,
                                  ]);
                              }
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={center} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>

              {!analyticsOpen && !showMetrics && (
                // status
                <div style={styles.statusSelect}>
                  <p style={{ color: "#1b4b84" }}>Status</p>
                  <Select
                    closeMenuOnSelect={false}
                    style={{
                      width: "200px",
                      marginLeft: "10px",
                      marginRight: "10px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                    // components={animatedComponents}
                    value={statusSelected}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setStatusSelected(e.target.value);
                    }}
                  >
                    {statuses.map((status, index) => {
                      return (
                        <MenuItem key={index} value={status}>
                          {status}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              )}

              <Button
                variant="contained"
                color="primary"
                style={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                onClick={() => {
                  console.log("clicked enter");
                  if (analyticsOpen) getAnalytics();
                  else if (!showMetrics) getJobs();
                  else setMetricsUpdated(metricsUpdated + 1);
                }}
              >
                Submit
              </Button>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "90%",
                flexDirection: "column",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  minWidth: "220px",
                  marginRight: "10px",
                }}
                onClick={() => {
                  setAdvancedFilterOpen(!advancedFilterOpen);
                }}
              >
                Base Filters
              </Button>
              <p style={{ margin: 0, padding: 0, color: "#1b4b84" }}>
                Start Date
              </p>
              <input
                type="date"
                onChange={(e) => {
                  console.log(e.target.value);
                  setStartDate(e.target.value);
                  // navigate(`/analytics?showAll=${showAll}&page=${page}&&startDate=${e.target.value}&endDate=${endDate}`);
                }}
                style={styles.filterDate}
              />
              <p style={{ margin: 0, padding: 0, color: "#1b4b84" }}>
                End Date
              </p>
              <input
                type="date"
                onChange={(e) => {
                  console.log(e.target.value);
                  setEndDate(e.target.value);
                  // navigate(`/jobs?showAll=${showAll}&page=${page}&startDate=${startDate}&endDate=${e.target.value}`);
                }}
                style={styles.filterDate}
              />
              <div style={styles.filtersWrapper}>
                <div style={styles.filterRow}>
                  <p style={{ color: "#1b4b84", marginRight: "10px" }}>
                    Staghorn
                  </p>
                  <Select
                    closeMenuOnSelect={false}
                    style={{
                      width: "200px",
                      height: "40px",
                    }}
                    // components={animatedComponents}
                    value={advancedFilters.staghorn}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setAdvancedFilters({
                        ...advancedFilters,
                        staghorn: e.target.value,
                      });
                    }}
                  >
                    <MenuItem value="Any">Any</MenuItem>
                    <MenuItem value="Yes">Yes</MenuItem>
                  </Select>
                </div>
                <div style={styles.filterRow}>
                  <p style={{ color: "#1b4b84", marginRight: "10px" }}>
                    StoneVolume
                  </p>
                  <Select
                    closeMenuOnSelect={false}
                    style={{
                      width: "200px",
                      height: "40px",
                    }}
                    // components={animatedComponents}
                    value={advancedFilters.stoneVolume}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setAdvancedFilters({
                        ...advancedFilters,
                        stoneVolume: e.target.value,
                      });
                    }}
                  >
                    <MenuItem value="Any">Any</MenuItem>
                    <MenuItem value="100">100</MenuItem>
                    <MenuItem value="500">500</MenuItem>
                    <MenuItem value="1000">1000</MenuItem>
                    <MenuItem value="5000">5000</MenuItem>
                  </Select>
                </div>
                <div style={styles.filterRow}>
                  <p style={{ color: "#1b4b84", marginRight: "10px" }}>
                    UreterStone
                  </p>
                  <Select
                    closeMenuOnSelect={false}
                    style={{
                      width: "200px",
                      height: "40px",
                    }}
                    // components={animatedComponents}
                    value={advancedFilters.ureter}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setAdvancedFilters({
                        ...advancedFilters,
                        ureter: e.target.value,
                      });
                    }}
                  >
                    <MenuItem value="Any">Any</MenuItem>
                    <MenuItem value="Yes">Yes</MenuItem>
                  </Select>
                </div>
                <div style={styles.filterRow}>
                  <p style={{ color: "#1b4b84", marginRight: "10px" }}>
                    HN/HUN
                  </p>
                  <Select
                    closeMenuOnSelect={false}
                    style={{
                      width: "200px",
                      height: "40px",
                    }}
                    // components={animatedComponents}
                    value={advancedFilters.hun}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setAdvancedFilters({
                        ...advancedFilters,
                        hun: e.target.value,
                      });
                    }}
                  >
                    <MenuItem value="Any">Any</MenuItem>
                    <MenuItem value="Mild">Mild</MenuItem>
                    <MenuItem value="Moderate">Moderate</MenuItem>
                    <MenuItem value="Severe">Severe</MenuItem>
                  </Select>
                </div>
                <div style={styles.filterRow}>
                  <p style={{ color: "#1b4b84", marginRight: "10px" }}>
                    Verified
                  </p>
                  <Select
                    closeMenuOnSelect={false}
                    style={{
                      width: "200px",
                      height: "40px",
                    }}
                    // components={animatedComponents}
                    value={advancedFilters.verified}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setAdvancedFilters({
                        ...advancedFilters,
                        verified: e.target.value,
                      });
                    }}
                  >
                    <MenuItem value="Any">Any</MenuItem>
                    <MenuItem value="Yes">Yes</MenuItem>
                  </Select>
                </div>
                <div style={styles.filterRow}>
                  <p style={{ color: "#1b4b84", marginRight: "10px" }}>
                    Combination
                  </p>
                  <Select
                    closeMenuOnSelect={false}
                    style={{
                      width: "200px",
                      height: "40px",
                    }}
                    // components={animatedComponents}
                    value={advancedFilters.combination}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setAdvancedFilters({
                        ...advancedFilters,
                        combination: e.target.value,
                      });
                    }}
                  >
                    <MenuItem value="And">And</MenuItem>
                    <MenuItem value="Or">Or</MenuItem>
                  </Select>
                </div>
              </div>
            </div>
          )}
          {analyticsOpen ? (
            <div style={styles.graphs}>
              <div id="pieChart" style={styles.graph}></div>
              <div id="barChart" style={styles.graph}></div>
              <div id="lineChart" style={styles.graph}></div>
            </div>
          ) : (
            <div>
              <Button
                variant="text"
                disabled={page <= 0}
                style={{
                  color: page <= 0 ? "#888" : "#1b4b84",
                }}
                onClick={() => {
                  console.log("previous page");
                  if (page > 0) {
                    setPage(page - 1);
                    // navigate(`/jobs?showAll=${showAll}&page=${page - 1}&filterText=${filterText}`);
                  }
                }}
              >
                <ArrowBackIcon />
              </Button>
              <span style={{ color: "black" }}>{parseInt(page) + 1}</span>
              <Button
                variant="text"
                disabled={jobs.length < 10}
                style={{
                  color: jobs.length < 10 ? "#888" : "#1b4b84",
                }}
                onClick={() => {
                  console.log("next page");
                  setPage(page + 1);
                  // navigate(`/jobs?showAll=${showAll}&page=${page + 1}&filterText=${filterText}&startDate=${startDate}&endDate=${endDate}`);
                }}
              >
                <ArrowForwardIcon />
              </Button>

              {mobile ? (
                <div
                  style={{
                    overflowX: "scroll",
                    width: "100%",
                    height: "100%",
                    paddingBottom: "30px",
                  }}
                >
                  <Entries
                    jobs={jobs}
                    statusObj={admin ? styles.statusObjAdmin : styles.statusObj}
                  />
                </div>
              ) : showMetrics ? (
                <Metrics
                  metricsUpdated={metricsUpdated}
                  analyticsAPIs={analyticsAPIs}
                  setLoading={setLoading}
                  startDate={startDate}
                  endDate={endDate}
                  centersSelected={centersSelected}
                  admin={admin}
                  token={localStorage.getItem(`token-${"aarthi"}`)}
                />
              ) : (
                <CasesTable
                  jobs={jobs}
                  admin={admin}
                  onJobIdClick={(target, jobId) => {
                    navigator.clipboard.writeText(jobId);
                    setToastMessage("Job ID copied to clipboard");
                    setToastOpen(true);
                    setToastPlacement("right-end");
                    setToastAnchorEl(target);
                    setTimeout(() => {
                      setToastOpen(false);
                      setToastMessage("");
                      setToastPlacement("");
                    }, 2000);
                  }}
                  onCaseClick={(job) => {
                    localStorage.setItem("center", job.center);
                    navigate(`/jobs?filterText=${job.jobId}`);
                  }}
                />
              )}
              {loading ? (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress
                    style={{
                      color: "#1b4b84",
                      margin: "auto",
                      marginTop: "100px",
                    }}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
