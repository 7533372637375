import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { apiURLs } from "./config";
import axios from "axios";
import { Link } from "react-router-dom";
import logo from "./urologiq.png";

const styles = {
  statusObj: (status) => ({
    borderRadius: "6px",
    width: "12px",
    height: "12px",
    background:
      status === "Down" ? "red" : status === "Active" ? "green" : "#528398",
    marginRight: "4px",
  }),
  statusWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "end",
  },
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, active, time) {
/*  if (active > 0) {
    return { name, status: "Down", time };
  } else {
    return { name, status: "Active", time };
  }
  */
  return { name, status: active < 60 * 10 ? "Active": "Down", time: Math.max(0, time) };
}

export default function CustomizedGatewayTable() {
  const [rows, setRows] = React.useState([]);
  React.useEffect(() => {
    const fetchData = () => {
      const token = localStorage.getItem(`token-aarthi`);
      axios
        .get(`${apiURLs["aarthi"]}/admin/gateway_status`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          let rows_res = [];
          const gateways = res.data.gateways; 
          for (let gateway of Object.keys(gateways)){
            console.log(gateway);
            let row = createData(
              gateway,
              gateways[gateway],
              gateways[gateway] - 60 * 10,
             // res.data.response[x] * 10
            );
            rows_res.push(row);
          }
          setRows(rows_res);
        })
        .catch((err) => {
          console.error("err", err);
        });
    };
    fetchData();
    const interval = setInterval(fetchData, 60000);
    return () => clearInterval(interval);
  }, []);
  return (
    <TableContainer>
      <TableRow>
        <TableCell sx={{ fontWeight: 700, fontSize: "20px" }}>
          <img src={logo} style={{ width: "100px" }} />
        </TableCell>
        <TableCell
          sx={{
            width: "25%",
            fontWeight: 700,
            fontSize: "20px",
          }}
        >
          Gateway Status
        </TableCell>
        <TableCell align="right" sx={{ width: "75%" }}>
          <Link
            className="dropActionlnk"
            to="/admin/jobs"
            params={{ admin: true }}
          >
            Back to Case Table
          </Link>{" "}
        </TableCell>
      </TableRow>
      <TableContainer
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Table sx={{ maxWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Center</StyledTableCell>
              <StyledTableCell align="right">Gateway Status</StyledTableCell>
              <StyledTableCell align="right">Downtime</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="right">
                  <div style={styles.statusWrapper}>
                    <div style={styles.statusObj(row.status)}></div>
                    {row.status}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="right">{row.time}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TableContainer>
  );
}
