import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// import { auth, signInWithEmailAndPassword, signInWithGoogle } from "./firebase";
import "./Login.css";
import "./AdminSignUp.css";
// import urologiq logo
import logo from "./urologiq.png";
import videoBg from "./assets/loginBgVid.mp4";
import { TextField, Button, CircularProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { apiURLs } from "./config";
import { Navigate } from "react-router-dom";
import Modal from "@mui/material/Modal";

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f5f5f5",
    overflow: "hidden",
  },
  videoContainer: {
    width: "65%",
    height: "100%",
    flex: "1",
    position: "relative",
  },
  video: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  loading: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  loginContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "420px",
    width: "400px",
    backgroundColor: "#f5f5f5",
    // borderRadius: "10px",
    // boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
    // border: "1px solid #e0e0e0",
  },
  email: {
    width: "250px",
    height: "50px",
    marginBottom: "15px",
  },
  password: {
    width: "250px",
    height: "50px",
    marginBottom: "20px",
  },
  center: {
    width: "250px",
    height: "50px",
    marginBottom: "15px",
  },
  loginBtn: {
    backgroundColor: "#1b4b84",
    color: "white",
    width: "220px",
    height: "40px",
    marginBottom: "10px",
  },
  error: {
    color: "red",
    marginTop: "10px",
    fontSize: "12px",
  },
  successModal: {
    background: "white",
    borderRadius: "5px",
    width: "600px",
    height: "400px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

function AdminSignUp(props) {
  const centers = Object.keys(apiURLs);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [center, setCenter] = useState("");
  const [error, setError] = useState("");
  const [admin, setAdmin] = useState(props.admin || false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [videoLoad, setvideoLoad] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [successModalOpen, setsuccessModalOpen] = useState(false);
  const [successErre, setsuccessErre] = useState([]);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    let isadmin = localStorage.getItem("admin");
    setAdmin(isadmin);
    console.log(isadmin);
  }, []);

  if (!admin) {
    const pathname = "/admin/login";
    // navigate with props
    return <Navigate to={{ pathname: pathname }} />;
  }
  const create_user = async (e) => {
    e.preventDefault();
    console.log("New_User", email, password, center, admin);
    setLoading(true);
    if (email === "" || password === "") {
      setError("Please fill in all fields");
      return;
    }
    if (!email.includes("@")) {
      setError("Please enter a valid email");
      return;
    }
    let erre = [];
    if (admin === "true") {
      const formData = new FormData();
      formData.append("username", email);
      formData.append("password", password);
      formData.append("admin", true);
      for (let i = 0; i < centers.length; i++) {
        const center = centers[i];
        const url = apiURLs[center] + "/create_user";
        let token = localStorage.getItem(`token-${center}`);
        console.log(token);
        let response;
        try {
          response = await fetch(url, {
            method: "POST",
            body: formData,
            headers: { Authorization: "Bearer " + token },
          });
          if (response) {
            erre.push(center);
          }
        } catch (err) {
          console.log("error", err);

          setError(response.error + " " + center);
          continue;
        }

        console.log(response, "response");
      }
    }
    setLoading(false);
    setsuccessErre(erre);
    setsuccessModalOpen(true);
  };

  if (resetPassword) {
    return (
      <Navigate
        to={{
          pathname: "/password_reset",
          state: { email: email, center: center, admin: admin },
        }}
      />
    );
  }

  function videoLoaded() {
    setvideoLoad(false);
  }

  return (
    <div style={styles.container}>
      <Modal
        open={successModalOpen}
        onClose={() => {
          setsuccessModalOpen(false);
        }}
      >
        <div style={styles.successModal}>
          <span style={{ color: "green" }}>
            <svg
              class="checkmarkX"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle
                class="checkmark__circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                class="checkmark__check"
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
            Thank you for enrolling an Admin user.
          </span>
          <div></div>
        </div>
      </Modal>
      <div style={styles.videoContainer} className="videoContainer">
        {videoLoad && (
          <div style={styles.loading}>
            <CircularProgress />
          </div>
        )}

        <video
          autoPlay
          muted
          loop
          src={videoBg}
          style={styles.video}
          onLoadedData={() => {
            videoLoaded();
          }}
        >
          <p>sorry your browser does'nt support embedded videos</p>
        </video>
      </div>
      <div style={styles.loginContainer}>
        <img src={logo} alt="Urologiq Logo" height={100} />
        <TextField
          style={styles.email}
          label="E-mail Address"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <TextField
          style={styles.password}
          label="Password"
          variant="outlined"
          value={password}
          type={showPassword ? "text" : "password"}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          style={styles.loginBtn}
          onClick={(e) => {
            // console.log('trying to login with ', email
            setError("");
            create_user(e);
          }}
        >
          Add User
        </Button>
        <div>
          Click{" "}
          <Link to="/admin/jobs" params={{ admin: true }}>
            here
          </Link>{" "}
          to go back to dashboard.
        </div>
        {error && <div style={styles.error}>{error}</div>}
        {loading && <CircularProgress />}
      </div>
    </div>
  );
}
export default AdminSignUp;
