import React, { useEffect, useState } from "react";
import { Link, } from "react-router-dom";

// import { auth, signInWithEmailAndPassword, signInWithGoogle } from "./firebase";
import "./Login.css";
// import urologiq logo
import logo from "./urologiq.png";
import { TextField, Button, CircularProgress } from "@mui/material";
import {apiURLs} from "./config";
import { Navigate } from "react-router-dom";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#f5f5f5",
  },
  loginContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "420px",
    width: "400px",
    backgroundColor: "#f5f5f5",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
    border: "1px solid #e0e0e0",
  },
  email: {
    width: "250px",
    height: "50px",
    marginBottom: "15px",
  },
  password: {
    width: "250px",
    height: "50px",
    marginBottom: "20px",
  },
  center: {
    width: "250px",
    height: "50px",
    marginBottom: "15px",
  },
  resetBtn: {
    backgroundColor: "#1b4b84",
    color: "white",
    width: "220px",
    height: "40px",
    marginBottom: "10px",
  },
  error: {
    color: "red",
    marginTop: "10px",
    fontSize: "12px",
    
  }
}

function PasswordReset(props) {

  const centers = Object.keys(apiURLs);
  
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [error, setError] = useState("");
  const [admin, setAdmin] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [center, setCenter] = useState('');
  const [resetDone, setResetDone] = useState(false);

  React.useEffect(() => {
    const center = localStorage.getItem('center');
    const admin = localStorage.getItem('admin');
    setCenter(center);
    setAdmin(admin === 'true');
  }, []);

  


  const reset = async (e) => {
    e.preventDefault();
    
    setError("");
    // check if email and password are empty
    if (verifyPassword === "" || newPassword === "" || oldPassword === "") {
      setError("Please fill in all fields");
      return;
    }
    // check password req 
    // 1. length > 8
    // 2. contains a number
    // 3. contains a special character
    // 4. contains a capital letter
    if (newPassword.length < 8) {
        setError("Password must be at least 8 characters");
        return;
    }
    if (!/\d/.test(newPassword)) {
        setError("Password must contain a number");
        return;
    }
    if (!/[!@#$%^&*]/.test(newPassword)) {
        setError("Password must contain a special character");
        return;
    }
    if (!/[A-Z]/.test(newPassword)) {
        setError("Password must contain a capital letter");
        return;
    }
    // check if passwords match
    if (verifyPassword !== newPassword) {
        setError("Passwords do not match");
        return;
    }

    setLoading(true);

    
    
    const formData = new FormData();
    formData.append("old_password", oldPassword);
    formData.append("new_password", verifyPassword);
    formData.append("admin", admin);

    if(admin){
      
      for(let i = 0; i < centers.length; i++){
        const token = localStorage.getItem(`token-${centers[i]}`);
        if(!token) continue;
        console.log(token, centers[i], 'token and center');
        const url = apiURLs[centers[i]] + "/reset_password";
        let response;
        try{
          response = await fetch(url, {
              method: "POST",
              body: formData,
              headers: {
                'Authorization': `Bearer ${token}`
              }
          });
        }catch(err){
          console.log('error', err, 'for', centers[i]);
          setError(`${err} for ${centers[i]}`);
          continue;
        }
        const data = await response.json();
        if (data.status) {
            console.log("response", data);
            setError("");
            // alert("Password reset successfully");
        } 
        else {
            setError(data.error + " for " + centers[i]);
        }
      }
      setTimeout(() => {
        setResetDone(true);
      }, 2000);

    }else {
      const token = localStorage.getItem('token');
      const url = apiURLs[center] + "/reset_password";
      const response = await fetch(url, {
          method: "POST",
          body: formData,
          headers: {
              'Authorization': `Bearer ${token}`
          },
      });
      const data = await response.json();
      if (data.status) {
          console.log("response", data);
          setTimeout(() => {
              setResetDone(true);
          }, 2000);
          setError("");
          alert("Password reset successfully");
      } 
      else {
              setError(data.error);
      }
    }

    setLoading(false);

  }

  if(resetDone) {
    const pathname = admin ? "/admin/jobs" : "/jobs";
    // navigate with props
    return <Navigate to={{ pathname: pathname}} />;
  }

  return (
    <div style={styles.container}>
      <div style={styles.loginContainer}>
        <img src={logo} alt="Urologiq Logo" height={100} />
        <p style={{ fontSize: "25px", fontWeight: "700", color: "#1b4b84", margin: 0 }}>Password Reset</p>
        <TextField
          style={styles.password}
          label="Old Password"
          variant="outlined"
          value={oldPassword}
          type="password"
          onChange={(e) => setOldPassword(e.target.value)}
          placeholder="Password"
        />
        <TextField
          style={styles.password}
          label="New Password"
          variant="outlined"
          value={newPassword}
          type="password"
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="Password"
        />
        <TextField
          style={styles.password}
          label="Verify Password"
          variant="outlined"
          value={verifyPassword}
          type="password"
          onChange={(e) => setVerifyPassword(e.target.value)}
          placeholder="Password"
        />
        <Button
          style={styles.resetBtn}
          onClick={(e) => {
            // console.log('trying to login with ', email
            setError("");
            reset(e);
          }}
        >
          Reset
        </Button>

        {
          error && <div
          style={styles.error}
          >{error}</div>
        }
        {
          loading && <CircularProgress />
        }
      </div>
    </div>
  );
}
export default PasswordReset;
