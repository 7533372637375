import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { DragDropContext } from "react-beautiful-dnd";
import Column from "./tasks";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { inferURLs, apiURLs, ohifURLs } from "./config";
import logo from "./urologiq.png";
import BarChartIcon from "@mui/icons-material/BarChart";
import ListIcon from "@mui/icons-material/List";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import MenuIcon from "@mui/icons-material/Menu";
import "./Jobs.css";

const styles = {
  wrapper: {
    width: "100%",
    height: "100%",
    // margin: '10px',
    // padding: '20px',
    background: "#fff",
    color: "black",
    overflow: "hidden",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    background: "#1b4a92",
    height: "40px",
    alignItems: "center",
    padding: "5px",
    color: "#fff",
  },
  dashboardWrapper: {
    // height: '100%',
    display: "flex",
    flexDirection: "row",
  },
  dashboardSelect: {
    fontSize: "20px",
    fontWeight: "700",
    color: "#fff",
    fontFamily: "monospace",
    height: "40px",
    padding: "0px",
    margin: "0px",
  },
  dashboardHeaderText: {
    fontSize: "20px",
    fontWeight: "700",
    color: "#fff",
    fontFamily: "Times New Roman",
  },
  menuButtonWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginInline: "20px",
    position: "absolute",
    top: "10px",
  },
  menuButton: {
    cursor: "pointer",
  },
  logoutButton: {
    color: "#fff",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  drawer: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 20px",
    width: "220px",
    height: "calc(100vh - 50px)",
    top: "50px",
    justifyContent: "flex-start",
    // background: 'red',
    background: "#fffeff",
    position: "absolute",
    zIndex: "1",
    boxShadow: "4px 20px 10px 1px rgba(0,0,0,0.75)",
    transition: "width 0.25s ease",
  },
  closedDrawer: {
    position: "absolute",
    width: "0px",
    height: "calc(100vh - 50px)",
    top: "50px",
    // background: 'red',
    zIndex: "-1",
    transition: "all 0.25s",
    overflow: "hidden",
  },
  drawerContent: {
    marginTop: "20px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    background: "#fff",
    color: "#111",
    height: "100vh",
    width: "100%",
    // padding: '20px',
  },
  logoutWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    // minWidth: '100px',
    marginRight: "20px",
  },
  statusWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // justifyContent: 'space-between',
  },
  statusObjAdmin: (status) => ({
    borderRadius: "6px",
    width: "12px",
    height: "12px",
    background:
      status === "Failed" ? "red" : status === "Finished" ? "green" : "#528398",
    marginRight: "4px",
  }),
  statusObj: (status) => ({
    borderRadius: "6px",
    width: "12px",
    height: "12px",
    background:
      status === "Failed"
        ? "red"
        : status === "completed"
        ? "green"
        : "#528398",
    marginRight: "4px",
  }),
  smallWidth: {
    maxWidth: "60px",
  },
  tableHeaderItem: {
    // color: '#049c77',
    textAlign: "center",
    verticalAlign: "middle",
    color: "#fff",
    border: "none",
    padding: "5px",
  },
  table: (pageHeight) => ({
    // borderRadius: '20px important!',
    border: "1px solid #111",
    // color: 'black',
    background: "#fff",
    color: "#111",
    // margin: '10px',
    marginBottom: pageHeight > 800 ? "10px" : "70px",
    fontWeight: "600",
    // width: '95%',
    // overflow: 'scroll',
    boxShadow: "0px 0px 10px 0px #000000",
  }),
  tableRow: (status, findings) => {
    return {
      background:
        status === "Finished"
          ? findings === true
            ? "#ffcccb"
            : "#aaeecc"
          : "#E0E0E0",
      color: "#111",
      fontWeight: "600",
      height: "30px",
      borderTop: "1px solid #0a0a12",
    };
  },
  tableBodyItem: {
    textAlign: "center",
    verticalAlign: "middle",
    maxHeight: "30px",
    borderWidth: "0px",
    borderStyle: "none",
    padding: "3px",
  },
  modal: {
    background: "white",
    borderRadius: "5px",
    width: "600px",
    height: "400px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  sequencesModal: {
    background: "white",
    borderRadius: "5px",
    width: "60vw",
    height: "90vh",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    justifyContent: "space-around",
    alignItems: "center",
  },
  sequencesModalRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "95%",
  },
  select: {
    width: "400px",
    height: "40px",
    fontSize: "20px",
    fontWeight: "700",
    background: "#fff",
    color: "#000",
  },
  deleteModal: {
    background: "white",
    borderRadius: "5px",
    width: "450px",
    height: "300px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  modalBodyItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  modalButton: {
    background: "#049c77",
    color: "white",
    width: "200px",
    height: "40px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  deleteJobIcon: {
    color: "red",
    cursor: "pointer",
  },
  feedbackModal: {
    background: "white",
    borderRadius: "5px",
    width: "600px",
    height: "400px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  feedback: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderRadius: "10px",
    padding: "10px",
    background: "#eeffee",
    marginBottom: "2px",
    marginTop: "2px",
  },
  feedbackFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  popper: {
    background: "white",
    borderRadius: "5px",
    padding: "10px",
  },
  reportModal: {
    background: "white",
    borderRadius: "5px",
    width: "500px",
    height: "300px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
  },
  reportTableHeaderItem: {
    textAlign: "center",
    verticalAlign: "middle",
    color: "#000",
  },
  reportTableBodyItem: {
    textAlign: "center",
    verticalAlign: "middle",
    maxHeight: "30px",
  },
  filterDate: {
    marginRight: "10px",
    marginLeft: "10px",
    border: "1px solid #000",
    borderRadius: "5px",
    padding: "5px",
  },

  // entries...
  entries: {
    display: "flex",
    flexDirection: "column",
    width: "95%",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 10px 25px 10px",
    // background: 'green',
  },
  entry: (status, findings) => ({
    minHeight: "100px",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    width: "100%",
    position: "relative",
    background:
      status === "Finished"
        ? findings === true
          ? "#ffcccb"
          : "#aaeecc"
        : "#E0E0E0",
    borderTop: "1px solid #888",
  }),
  entryopen: (status, findings) => ({
    minHeight: "100px",
    display: "flex",
    flexDirection: "column",
    padding: "10px 10px 35px 10px",
    width: "100%",
    position: "relative",
    background:
      status === "Finished"
        ? findings === true
          ? "#ffcccb"
          : "#aaeecc"
        : "#E0E0E0",
    borderTop: "1px solid #888",
  }),
  entryRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    margin: "5px",
  },
  entryRowKey: {
    fontSize: "12pt",
    fontWeight: "700",
    color: "#000",
  },
  entryRowValue: {
    fontSize: "12pt",
    fontWeight: "400",
    color: "#000",
  },
  expandButtonWrapper: {
    position: "absolute",
    left: "10px",
    bottom: "2px",
    padding: "10px",
    backgroundColor: "#1b4b84",
    color: "white",
    fontSize: "12px",
    cursor: "pointer",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
  },
  dropdownContent: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    backgroundColor: "white",
    minWidth: "220px",
    boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
    zIndex: "1",
    borderRadius: "5px",
    border: "1px solid #1b4b84",
    padding: "10px",
    // transform: 'translateX(-50%)',
    right: "5px",
    height: "fit-content",
  },

  dropdownContentItem: {
    padding: "5px",
    cursor: "pointer",
    color: "#000",
    "&:hover": {
      background: "#1b4b84",
      color: "white",
    },
    display: "flex",
    alignItems: "center",
    margin: "5px",
    fontWeight: "100",
    fontSize: "1rem",
  },
  badge: {
    display: "inline-block",
    backgroundColor: "#1b4b84",
    color: "#fff",
    borderRadius: "50%",
    width: "16px",
    height: "16px",
    lineHeight: "16px",
    textAlign: "center",
    verticalAlign: "middle",
    fontSize: "9px",
    position: "relative",
    top: "-10px",
    left: "-2px",
  },
};
export default function KanbanBoard() {
  const location = useLocation();
  const [baseURLall, setBaseURLall] = useState(inferURLs["aarthi"]);
  const [baseURL, setBaseURL] = useState("");
  const [center, setCenter] = useState("");
  const [admin, setAdmin] = useState("");
  const [completed, setFinished] = useState([]);
  const [ongoing, setOngoing] = useState([]);
  const [verified, setVerified] = useState([]);
  const [drop, setDrop] = useState("");
  const [dateFilter, setDateFilter] = useState("today");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const handleCenterChange = (center) => {
    setCenter(center);
    setBaseURL(inferURLs[center]);
    localStorage.setItem("center", center);
  };
  useEffect(() => {
    // get center from local storage
    const center = localStorage.getItem("center");
    const admin = localStorage.getItem("admin");
    setCenter(center);
    setAdmin(admin);
    setBaseURLall(inferURLs["aarthi"]);
    setBaseURL(inferURLs[center]);
  }, []);
  useEffect(() => {
    const fetchData = () => {
      const token = localStorage.getItem(`token-aarthi`);
      axios
        .get(`${baseURLall}/jobssortedtasks?date_filter=${dateFilter}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setVerified(res.data.verified_jobs);
          setFinished(res.data.finished_jobs);
          setOngoing(res.data.on_verification_jobs);
        })
        .catch((err) => {
          console.error("err", err);
        });
    };
    fetchData();
    const interval = setInterval(fetchData, 10000);

    return () => clearInterval(interval);
  }, [dateFilter, drop]);

  const handleDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (source.droppableId == destination.droppableId) return;

    //REMOVE FROM SOURCE ARRAY

    if (source.droppableId == 1) {
      setFinished(removeItemById(draggableId, completed));
    } else {
      setOngoing(removeItemById(draggableId, ongoing));
    }

    // GET ITEM

    const task = findItemById(draggableId, [...ongoing, ...completed]);

    //ADD ITEM
    if (destination.droppableId == 2) {
      const formData1 = new FormData();
      const username = localStorage.getItem("username");
      formData1.append("job_id", task.jobId);
      formData1.append("update_key", "current_user");
      formData1.append("update_val", username);
      const token1 = localStorage.getItem(`token-${task.center}`);
      axios
        .post(`${apiURLs[task.center]}/job_update`, formData1, {
          headers: {
            Authorization: `Bearer ${token1}`,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            console.log(res.data.success);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      const formData = new FormData();
      formData.append("job_id", task.jobId);
      formData.append("update_key", "on_verification");
      formData.append("update_val", true);
      const token = localStorage.getItem(`token-${task.center}`);
      axios
        .post(`${apiURLs[task.center]}/job_update`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            setOngoing([task, ...ongoing]);
            handleCenterChange(task.center);
            navigate(`/view/${task.jobId}`);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const formData1 = new FormData();
      formData1.append("job_id", task.jobId);
      formData1.append("update_key", "current_user");
      formData1.append("update_val", "");
      const token1 = localStorage.getItem(`token-${task.center}`);
      axios
        .post(`${apiURLs[task.center]}/job_update`, formData1, {
          headers: {
            Authorization: `Bearer ${token1}`,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            setDrop(task.jobId);
            console.log(res.data.success);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      const formData = new FormData();
      formData.append("job_id", task.jobId);
      formData.append("update_key", "on_verification");
      formData.append("update_val", false);
      const token = localStorage.getItem(`token-${task.center}`);
      axios
        .post(`${apiURLs[task.center]}/job_update`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            setFinished([task, ...completed]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  function findItemById(id, array) {
    console.log(array.find((item) => item.jobId == id));
    return array.find((item) => item.jobId == id);
  }

  function removeItemById(id, array) {
    return array.filter((item) => item.jobId != id);
  }
  function toggleDrawer() {
    setDrawerOpen(!drawerOpen);
  }
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div
        style={{
          fontFamily: "monospace",
          overflow: "hidden",
        }}
      >
        <div style={drawerOpen ? styles.drawer : styles.closedDrawer}>
          <img
            src={logo}
            style={{ width: "100%", height: "auto", marginBottom: "20px" }}
          />
          <div style={styles.drawerContent}>
            <div
              className={
                location.pathname === "/jobs"
                  ? "drawerItem Active"
                  : "drawerItem Inactive"
              }
              onClick={() => navigate("/jobs")}
            >
              <ListIcon style={{ marginRight: "10px" }} />
              <p
                style={{
                  margin: "0px",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Jobs
              </p>
            </div>
            <div
              className={
                location.pathname === "/jobsall"
                  ? "drawerItem Active"
                  : "drawerItem Inactive"
              }
              onClick={() => navigate("/admin/jobsall")}
            >
              <ListIcon style={{ marginRight: "10px" }} />
              <p
                style={{
                  margin: "0px",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                All Centers Jobs
              </p>
            </div>
            <div
              className={
                location.pathname === "/tasks"
                  ? "drawerItem Active"
                  : "drawerItem Inactive"
              }
              onClick={() => navigate("/admin/tasks")}
            >
              <ListIcon style={{ marginRight: "10px" }} />
              <p
                style={{
                  margin: "0px",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Tasks List
              </p>
            </div>
            <div
              className={
                location.pathname === "/analytics"
                  ? "drawerItem Active"
                  : "drawerItem Inactive"
              }
              onClick={() => navigate("/analytics")}
            >
              <BarChartIcon style={{ marginRight: "10px" }} />
              <p
                style={{
                  margin: "0px",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Analytics
              </p>
            </div>
            <div
              className={
                location.pathname === "/user-management"
                  ? "drawerItem Active"
                  : "drawerItem Inactive"
              }
              onClick={() => navigate("/admin/user-management")}
            >
              <PersonAddIcon style={{ marginRight: "10px" }} />
              <p
                style={{
                  margin: "0px",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                User Management
              </p>
            </div>
            <div
              className={
                location.pathname === "/dockerstatus"
                  ? "drawerItem Active"
                  : "drawerItem Inactive"
              }
              onClick={() => navigate("/admin/dockerstatus")}
            >
              <BarChartIcon style={{ marginRight: "10px" }} />
              <p
                style={{
                  margin: "0px",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Docker Status
              </p>
            </div>
          </div>
        </div>
        <div style={styles.dashboardWrapper}>
          <div
            style={styles.menuButtonWrapper}
            className="menuButtonWrapper"
            onClick={toggleDrawer}
          >
            {admin ? (
              <MenuIcon
                style={styles.menuButton}
                onClick={(e) => {
                  e.stopPropagation();
                  // open drawer
                  toggleDrawer();
                }}
              />
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: { border: "1px solid grey", bgcolor: "#fff" },
                },
              }}
              title={
                <Box
                  sx={{
                    width: 250,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    id="input-slider"
                    gutterBottom
                    sx={{
                      width: "100%",
                      border: "none",
                      borderRadius: "15px 50px",
                      background: "#DE1D6E",
                      color: "#fff",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setDateFilter("today");
                    }}
                  >
                    Today's
                  </Typography>
                  <Typography
                    id="input-slider"
                    gutterBottom
                    sx={{
                      width: "100%",
                      border: "none",
                      borderRadius: "15px 50px",
                      background: "#4F1DDE",
                      color: "#fff",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setDateFilter("week");
                    }}
                  >
                    Week's
                  </Typography>
                </Box>
              }
            >
              <h2
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  width: "200px",
                }}
              >
                &#129133; {dateFilter.toUpperCase()}'S BOARD
              </h2>
            </Tooltip>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Column title={"Not Verified"} tasks={completed} id={"1"} />
          <div style={{ fontSize: "80px" }}>⇄</div>
          <Column title={"On Verification"} tasks={ongoing} id={"2"} />
          {/* <Column title={"Verified"} tasks={verified} id={"3"} /> */}
        </div>
      </div>
    </DragDropContext>
  );
}
