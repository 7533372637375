import React from "react";
import { Select, MenuItem } from "@mui/material";
const styles = {
  metrics: {
    // width: "500px",
    background: "#fff",
    color: "#111",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
    marginTop: "10px",
  },
};

const minColor = [255, 255, 255];
const maxColor = [20, 20, 255];
const getCellColor = (value, max, min) => {
  const ratio = (value - min) / (max - min);
  const color = [];
  for (let i = 0; i < 3; i++) {
    color.push(Math.round(minColor[i] + ratio * (maxColor[i] - minColor[i])));
  }
  return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
};

const getMaxValue = (matrix) => {
  console.log("matrix", matrix);
  let max = Number.MIN_SAFE_INTEGER;
  matrix.forEach((row) => {
    row.forEach((value) => {
      if (value > max) {
        max = value;
      }
    });
  });
  return max;
};

const getMinValue = (matrix) => {
  console.log("matrix", matrix);
  let min = Number.MAX_SAFE_INTEGER;
  matrix.forEach((row) => {
    row.forEach((value) => {
      if (value < min) {
        min = value;
      }
    });
  });
  return min;
};

const Switch = (props) => {
  return (
    <input
      style={{
        height: "20px",
        width: "20px",
        marginRight: "10px",
        cursor: "pointer",
        verticalAlign: "middle",
        position: "relative",
      }}
      type="checkbox"
      checked={props.checked}
      onChange={props.onChange}
    />
  );
};

const FindingsMetrics = (props) => {
  //   const [whichOrgan, setWhichOrgan] = React.useState("all");
  //   const [max, setMax] = React.useState(
  //     getMaxValue(props.metrics.confusionMatrix[whichOrgan])
  //   );
  //   const [min, setMin] = React.useState(
  //     getMinValue(props.metrics.confusionMatrix[whichOrgan])
  //   );
  //   React.useEffect(() => {
  //     setMax(getMaxValue(props.metrics.confusionMatrix[whichOrgan]));
  //     setMin(getMinValue(props.metrics.confusionMatrix[whichOrgan]));
  //   }, [whichOrgan, props.metrics.confusionMatrix]);
  return (
    <div style={styles.metrics}>
      {/* <Select
        value={whichOrgan}
        onChange={(e) => setWhichOrgan(e.target.value)}
      >
        <MenuItem value={"all"} key={"all"}>
          All
        </MenuItem>
        <MenuItem value={"leftKidney"} key={"leftkidney"}>
          Left Kidney
        </MenuItem>
        <MenuItem value={"rightKidney"} key={"rightkidney"}>
          Right Kidney
        </MenuItem>
        <MenuItem value={"leftUreter"} key={"leftureter"}>
          Left Ureter
        </MenuItem>
        <MenuItem value={"rightUreter"} key={"rightureter"}>
          Right Ureter
        </MenuItem>
        <MenuItem value={"bladder"} key={"bladder"}>
          Bladder
        </MenuItem>
      </Select> */}
      <h3>Without Stent Metrics</h3>
      {props.metrics ? (
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "20px",
          }}
        >
          <thead>
            <tr
              style={{ background: "#f2f2f2", borderBottom: "1px solid #ddd" }}
            >
              <th
                style={{
                  padding: "10px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Recall
              </th>
              <th
                style={{
                  padding: "10px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Precision
              </th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ borderBottom: "1px solid #ddd" }}>
              <td
                style={{
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                {props.metrics["recall"]["0"]}
              </td>
              <td
                style={{
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                {props.metrics["precision"]["0"]}
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <div>None</div>
      )}
      {/* <h3>Metrics</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={styles.reportTableHeaderItem}>Metric</th>
            <th style={styles.reportTableHeaderItem}>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={styles.reportTableBodyItem}>Sensitivity</td>
            <td style={styles.reportTableBodyItem}>
              {Math.round(props.metrics.recall[whichOrgan] * 10000) / 100}%
            </td>
          </tr>
          <tr>
            <td style={styles.reportTableBodyItem}>PPV (Precision)</td>
            <td style={styles.reportTableBodyItem}>
              {Math.round(props.metrics.precision[whichOrgan] * 10000) / 100}%
            </td>
          </tr>
          <tr>
            <td style={styles.reportTableBodyItem}>Specificity</td>
            <td style={styles.reportTableBodyItem}>
              {Math.round(props.metrics.specificity[whichOrgan] * 10000) / 100}%
            </td>
          </tr>
          <tr>
            <td style={styles.reportTableBodyItem}>NPV</td>
            <td style={styles.reportTableBodyItem}>
              {Math.round(props.metrics.npv[whichOrgan] * 10000) / 100}%
            </td>
          </tr>
          <tr>
            <td style={styles.reportTableBodyItem}>F1</td>
            <td style={styles.reportTableBodyItem}>
              {Math.round(props.metrics.f1[whichOrgan] * 10000) / 100}%
            </td>
          </tr>
        </tbody>
      </table> */}
    </div>
  );
};

const PFSMetrics = (props) => {
  //   const max = getMaxValue(props.metrics.confusionMatrix);
  //   const min = getMinValue(props.metrics.confusionMatrix);
  //   const [showCombinedMetrics, setShowCombinedMetrics] = React.useState(true);
  return (
    <div style={styles.metrics}>
      {/* <Switch
        checked={showCombinedMetrics}
        onChange={() => setShowCombinedMetrics(!showCombinedMetrics)}
      />{" "}
      {showCombinedMetrics
        ? "Show Individual Metrics"
        : "Show Combined Metrics"} */}
      <h3>Organ Wise Metrics</h3>
      <table
        style={{
          borderCollapse: "collapse",
          marginBlock: "10px",
          width: "100%",
          fontFamily: "Rajdhani",
        }}
      >
        <thead>
          <tr style={{ background: "#f2f2f2", borderBottom: "1px solid #ddd" }}>
            <th
              style={{
                padding: "10px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Organ
            </th>
            <th
              style={{
                padding: "10px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Precision
            </th>
            <th
              style={{
                padding: "10px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Recall
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(props.metrics["precision"]).map(
            ([name, value], i) => {
              return (
                <tr key={i}>
                  <td
                    style={{
                      padding: "5px 10px",
                      fontSize: "12pt",
                      fontWeight: "600",
                      border: "1px solid #fff",
                    }}
                  >
                    {name}
                  </td>
                  <td
                    style={{
                      padding: "5px 10px",
                      fontSize: "12pt",
                      border: "1px solid #fff",
                    }}
                  >
                    {value}
                  </td>
                  <td
                    style={{
                      padding: "5px 10px",
                      fontSize: "12pt",
                      border: "1px solid #fff",
                    }}
                  >
                    {props.metrics["recall"][name]}
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </div>
  );
};

const HNMetrics = (props) => {
  const max = getMaxValue(props.metrics.confusionMatrix);
  const min = getMinValue(props.metrics.confusionMatrix);
  const [showCombinedMetrics, setShowCombinedMetrics] = React.useState(true);
  return (
    <div style={styles.metrics}>
      <Switch
        checked={showCombinedMetrics}
        onChange={() => setShowCombinedMetrics(!showCombinedMetrics)}
        // color="primary"
      />{" "}
      {showCombinedMetrics
        ? "Show Individual Metrics"
        : "Show Combined Metrics"}
      <h3>Confusion Matrix</h3>
      {showCombinedMetrics ? (
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "20px",
          }}
        >
          <thead>
            <tr
              style={{ background: "#f2f2f2", borderBottom: "1px solid #ddd" }}
            >
              <th
                style={{
                  padding: "10px",
                  textAlign: "left",
                  fontWeight: "bold",
                }}
              ></th>
              <th
                style={{
                  padding: "10px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Actual No
              </th>
              <th
                style={{
                  padding: "10px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Actual Mild
              </th>
              <th
                style={{
                  padding: "10px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Actual Moderate
              </th>
              <th
                style={{
                  padding: "10px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Actual Severe
              </th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ borderBottom: "1px solid #ddd" }}>
              <td
                style={{
                  padding: "10px",
                  textAlign: "left",
                  fontWeight: "bold",
                  borderRight: "1px solid #ddd",
                }}
              >
                Predicted No
              </td>
              <td
                style={{
                  padding: "10px",
                  textAlign: "center",
                  background: getCellColor(
                    props.metrics.confusionMatrix[0][0],
                    max,
                    min
                  ),
                }}
              >
                {props.metrics.confusionMatrix[0][0]}
              </td>
              <td
                style={{
                  padding: "10px",
                  textAlign: "center",
                  background: getCellColor(
                    props.metrics.confusionMatrix[0][1],
                    max,
                    min
                  ),
                }}
              >
                {props.metrics.confusionMatrix[0][1]}
              </td>
              <td
                style={{
                  padding: "10px",
                  textAlign: "center",
                  background: getCellColor(
                    props.metrics.confusionMatrix[0][2],
                    max,
                    min
                  ),
                }}
              >
                {props.metrics.confusionMatrix[0][2]}
              </td>
              <td
                style={{
                  padding: "10px",
                  textAlign: "center",
                  background: getCellColor(
                    props.metrics.confusionMatrix[0][3],
                    max,
                    min
                  ),
                }}
              >
                {props.metrics.confusionMatrix[0][3]}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  padding: "10px",
                  textAlign: "left",
                  fontWeight: "bold",
                  borderRight: "1px solid #ddd",
                }}
              >
                Predicted Mild
              </td>
              <td
                style={{
                  padding: "10px",
                  textAlign: "center",
                  background: getCellColor(
                    props.metrics.confusionMatrix[1][0],
                    max,
                    min
                  ),
                }}
              >
                {props.metrics.confusionMatrix[1][0]}
              </td>
              <td
                style={{
                  padding: "10px",
                  textAlign: "center",
                  background: getCellColor(
                    props.metrics.confusionMatrix[1][1],
                    max,
                    min
                  ),
                }}
              >
                {props.metrics.confusionMatrix[1][1]}
              </td>
              <td
                style={{
                  padding: "10px",
                  textAlign: "center",
                  background: getCellColor(
                    props.metrics.confusionMatrix[1][2],
                    max,
                    min
                  ),
                }}
              >
                {props.metrics.confusionMatrix[1][2]}
              </td>
              <td
                style={{
                  padding: "10px",
                  textAlign: "center",
                  background: getCellColor(
                    props.metrics.confusionMatrix[1][3],
                    max,
                    min
                  ),
                }}
              >
                {props.metrics.confusionMatrix[1][3]}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  padding: "10px",
                  textAlign: "left",
                  fontWeight: "bold",
                  borderRight: "1px solid #ddd",
                }}
              >
                Predicted Moderate
              </td>
              <td
                style={{
                  padding: "10px",
                  textAlign: "center",
                  background: getCellColor(
                    props.metrics.confusionMatrix[2][0],
                    max,
                    min
                  ),
                }}
              >
                {props.metrics.confusionMatrix[2][0]}
              </td>
              <td
                style={{
                  padding: "10px",
                  textAlign: "center",
                  background: getCellColor(
                    props.metrics.confusionMatrix[2][1],
                    max,
                    min
                  ),
                }}
              >
                {props.metrics.confusionMatrix[2][1]}
              </td>
              <td
                style={{
                  padding: "10px",
                  textAlign: "center",
                  background: getCellColor(
                    props.metrics.confusionMatrix[2][2],
                    max,
                    min
                  ),
                }}
              >
                {props.metrics.confusionMatrix[2][2]}
              </td>
              <td
                style={{
                  padding: "10px",
                  textAlign: "center",
                  background: getCellColor(
                    props.metrics.confusionMatrix[2][3],
                    max,
                    min
                  ),
                }}
              >
                {props.metrics.confusionMatrix[2][3]}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  padding: "10px",
                  textAlign: "left",
                  fontWeight: "bold",
                  borderRight: "1px solid #ddd",
                }}
              >
                Predicted Severe
              </td>
              <td
                style={{
                  padding: "10px",
                  textAlign: "center",
                  background: getCellColor(
                    props.metrics.confusionMatrix[3][0],
                    max,
                    min
                  ),
                }}
              >
                {props.metrics.confusionMatrix[3][0]}
              </td>
              <td
                style={{
                  padding: "10px",
                  textAlign: "center",
                  background: getCellColor(
                    props.metrics.confusionMatrix[3][1],
                    max,
                    min
                  ),
                }}
              >
                {props.metrics.confusionMatrix[3][1]}
              </td>
              <td
                style={{
                  padding: "10px",
                  textAlign: "center",
                  background: getCellColor(
                    props.metrics.confusionMatrix[3][2],
                    max,
                    min
                  ),
                }}
              >
                {props.metrics.confusionMatrix[3][2]}
              </td>
              <td
                style={{
                  padding: "10px",
                  textAlign: "center",
                  background: getCellColor(
                    props.metrics.confusionMatrix[3][3],
                    max,
                    min
                  ),
                }}
              >
                {props.metrics.confusionMatrix[3][3]}
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              marginTop: "20px",
              marginRight: "10px",
            }}
          >
            <thead>
              <tr
                style={{
                  background: "#f2f2f2",
                  borderBottom: "1px solid #ddd",
                }}
              >
                <th
                  style={{
                    padding: "10px",
                    textAlign: "left",
                    fontWeight: "bold",
                  }}
                ></th>
                <th
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Actual No
                </th>
                <th
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Actual Mild
                </th>
                <th
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Actual Moderate
                </th>
                <th
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Actual Severe
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ borderBottom: "1px solid #ddd" }}>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "left",
                    fontWeight: "bold",
                    borderRight: "1px solid #ddd",
                  }}
                >
                  Predicted No
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixLeft[0][0],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixLeft[0][0]}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixLeft[0][1],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixLeft[0][1]}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixLeft[0][2],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixLeft[0][2]}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixLeft[0][3],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixLeft[0][3]}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "left",
                    fontWeight: "bold",
                    borderRight: "1px solid #ddd",
                  }}
                >
                  Predicted Mild
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixLeft[1][0],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixLeft[1][0]}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixLeft[1][1],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixLeft[1][1]}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixLeft[1][2],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixLeft[1][2]}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixLeft[1][3],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixLeft[1][3]}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "left",
                    fontWeight: "bold",
                    borderRight: "1px solid #ddd",
                  }}
                >
                  Predicted Moderate
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixLeft[2][0],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixLeft[2][0]}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixLeft[2][1],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixLeft[2][1]}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixLeft[2][2],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixLeft[2][2]}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixLeft[2][3],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixLeft[2][3]}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "left",
                    fontWeight: "bold",
                    borderRight: "1px solid #ddd",
                  }}
                >
                  Predicted Severe
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixLeft[3][0],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixLeft[3][0]}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixLeft[3][1],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixLeft[3][1]}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixLeft[3][2],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixLeft[3][2]}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixLeft[3][3],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixLeft[3][3]}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr
                style={{
                  background: "#f2f2f2",
                  borderBottom: "1px solid #ddd",
                }}
              >
                <th
                  style={{
                    padding: "10px",
                    textAlign: "left",
                    fontWeight: "bold",
                  }}
                ></th>
                <th
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Actual No
                </th>
                <th
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Actual Mild
                </th>
                <th
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Actual Moderate
                </th>
                <th
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Actual Severe
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ borderBottom: "1px solid #ddd" }}>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "left",
                    fontWeight: "bold",
                    borderRight: "1px solid #ddd",
                  }}
                >
                  Predicted No
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixRight[0][0],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixRight[0][0]}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixRight[0][1],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixRight[0][1]}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixRight[0][2],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixRight[0][2]}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixRight[0][3],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixRight[0][3]}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "left",
                    fontWeight: "bold",
                    borderRight: "1px solid #ddd",
                  }}
                >
                  Predicted Mild
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixRight[1][0],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixRight[1][0]}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixRight[1][1],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixRight[1][1]}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixRight[1][2],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixRight[1][2]}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixRight[1][3],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixRight[1][3]}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "left",
                    fontWeight: "bold",
                    borderRight: "1px solid #ddd",
                  }}
                >
                  Predicted Moderate
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixRight[2][0],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixRight[2][0]}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixRight[2][1],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixRight[2][1]}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixRight[2][2],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixRight[2][2]}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixRight[2][3],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixRight[2][3]}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "left",
                    fontWeight: "bold",
                    borderRight: "1px solid #ddd",
                  }}
                >
                  Predicted Severe
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixRight[3][0],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixRight[3][0]}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixRight[3][1],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixRight[3][1]}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixRight[3][2],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixRight[3][2]}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    background: getCellColor(
                      props.metrics.confusionMatrixRight[3][3],
                      max,
                      min
                    ),
                  }}
                >
                  {props.metrics.confusionMatrixRight[3][3]}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const CalculiMetrics = (props) => {
  //   const [whichOrgan, setWhichOrgan] = React.useState("all");
  //   const [max, setMax] = React.useState(
  //     getMaxValue(props.metrics.confusionMatrix[whichOrgan])
  //   );
  //   const [min, setMin] = React.useState(
  //     getMinValue(props.metrics.confusionMatrix[whichOrgan])
  //   );
  //   React.useEffect(() => {
  //     setMax(getMaxValue(props.metrics.confusionMatrix[whichOrgan]));
  //     setMin(getMinValue(props.metrics.confusionMatrix[whichOrgan]));
  //   }, [whichOrgan, props.metrics.confusionMatrix]);
  //   console.log(
  //     props.metrics.confusionMatrix[whichOrgan],
  //     props.metrics.confusionMatrix[whichOrgan][0][0],
  //     "whichOrgan",
  //     whichOrgan
  //   );
  return (
    <div style={styles.metrics}>
      {/* <Select
        value={whichOrgan}
        onChange={(e) => setWhichOrgan(e.target.value)}
      >
        <MenuItem value={"all"} key={"all"}>
          All
        </MenuItem>
        <MenuItem value={"leftkidney"} key={"leftkidney"}>
          Left Kidney
        </MenuItem>
        <MenuItem value={"rightkidney"} key={"rightkidney"}>
          Right Kidney
        </MenuItem>
        <MenuItem value={"leftureter"} key={"leftureter"}>
          Left Ureter
        </MenuItem>
        <MenuItem value={"rightureter"} key={"rightureter"}>
          Right Ureter
        </MenuItem>
        <MenuItem value={"bladder"} key={"bladder"}>
          Bladder
        </MenuItem>
      </Select>
      <h3>Confusion Matrix</h3>
      <table
        style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}
      >
        <thead>
          <tr style={{ background: "#f2f2f2", borderBottom: "1px solid #ddd" }}>
            <th
              style={{ padding: "10px", textAlign: "left", fontWeight: "bold" }}
            ></th>
            <th
              style={{
                padding: "10px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Actual Positive
            </th>
            <th
              style={{
                padding: "10px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Actual Negative
            </th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ borderBottom: "1px solid #ddd" }}>
            <td
              style={{
                padding: "10px",
                textAlign: "left",
                fontWeight: "bold",
                borderRight: "1px solid #ddd",
              }}
            >
              Predicted Positive
            </td>
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                background: getCellColor(
                  props.metrics.confusionMatrix[whichOrgan][0][0],
                  max,
                  min
                ),
              }}
            >
              {props.metrics.confusionMatrix[whichOrgan][0][0]}
            </td>
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                background: getCellColor(
                  props.metrics.confusionMatrix[whichOrgan][0][1],
                  max,
                  min
                ),
              }}
            >
              {props.metrics.confusionMatrix[whichOrgan][0][1]}
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: "10px",
                textAlign: "left",
                fontWeight: "bold",
                borderRight: "1px solid #ddd",
              }}
            >
              Predicted Negative
            </td>
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                background: getCellColor(
                  props.metrics.confusionMatrix[whichOrgan][1][0],
                  max,
                  min
                ),
              }}
            >
              {props.metrics.confusionMatrix[whichOrgan][1][0]}
            </td>
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                background: getCellColor(
                  props.metrics.confusionMatrix[whichOrgan][1][1],
                  max,
                  min
                ),
              }}
            >
              -
            </td>
          </tr>
        </tbody>
      </table>
      <h3>Metrics</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={styles.reportTableHeaderItem}>Metric</th>
            <th style={styles.reportTableHeaderItem}>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={styles.reportTableBodyItem}>Accuracy</td>
            <td style={styles.reportTableBodyItem}>
              {Math.round(props.metrics.accuracy[whichOrgan] * 10000) / 100}%
            </td>
          </tr>
          <tr>
            <td style={styles.reportTableBodyItem}>PPV (Precision)</td>
            <td style={styles.reportTableBodyItem}>
              {Math.round(props.metrics.precision[whichOrgan] * 10000) / 100}%
            </td>
          </tr>
          <tr>
            <td style={styles.reportTableBodyItem}>Sensitivity</td>
            <td style={styles.reportTableBodyItem}>
              {Math.round(props.metrics.recall[whichOrgan] * 10000) / 100}%
            </td>
          </tr>
          <tr>
            <td style={styles.reportTableBodyItem}>F1</td>
            <td style={styles.reportTableBodyItem}>
              {Math.round(props.metrics.f1[whichOrgan] * 10000) / 100}%
            </td>
          </tr>
        </tbody>
      </table> */}
      invalid response
    </div>
  );
};

export { FindingsMetrics, PFSMetrics, HNMetrics, CalculiMetrics };
