import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiURLs } from "./config";
import Select from "react-select";
import chroma from "chroma-js";


const styles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : undefined,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? chroma.contrast(color, "white") > 2
          ? "white"
          : "black"
        : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : color.alpha(0.3).css()
          : undefined,
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) =>
    !data.isFixed
      ? {
          ...styles,
          color: data.color,
          ":hover": {
            backgroundColor: data.color,
            color: "white",
          },
        }
      : { ...styles, display: "none" },
};

const orderOptions = (values) => {
  return values.filter((v) => v.isFixed);
};

export const AdminAdd = (props) => {
  const user = props.user
  const [value, setValue] = useState([]);
  const [names, setNames] = useState(null);
  const [fetchcount, setFetchCount] = useState(0);

  const [responseData, setResponseData] = useState([]);

  const centers = Object.keys(apiURLs).sort();
  const jivaindex = centers.indexOf("jiva-cropped");
  const x = centers.splice(jivaindex, 1);
  const sendPostRequest = async (url, data) => {
    try {
      const response = await axios.post(url, data);
      if (response.data.option) {
        return response.data.option;
      } else {
        return {
          value: url.split("/")[4],
          label: url.split("/")[4],
          color: "#36B37E",
          isFixed: false,
        };
      }
    } catch (error) {
      console.error(error);
    }
  };
  const sendAddUserRequest = async (url, data) => {
    try {
      const token = localStorage.getItem(`token-aarthi`);
      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const handleMultipleRequests = async (centers, requestData) => {
    const responses = await Promise.all(
      centers.map((center) =>
        sendPostRequest(apiURLs[center] + "/user_check", requestData)
      )
    );
    setResponseData(responses);
    setValue(orderOptions(responses));
  };
  const runAfterDataFetch = () => {
    let formData = new FormData();
    formData.set("username", user["username"]);
    handleMultipleRequests(centers, formData);
  };

  useEffect(() => {
    let token = localStorage.getItem(`token-aarthi`);
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiURLs.aarthi}/getusers`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setNames(response.data.admin_users);
        if (fetchcount == 0) {
          setFetchCount((prevCount) => prevCount + 1);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // This effect will run after data is updated

    fetchData();

    if (names !== null) {
      runAfterDataFetch();
    }
  }, [fetchcount]);

  const onChange = async (newValue, actionMeta) => {
    switch (actionMeta.action) {
      case "remove-value":
      case "pop-value":
        if (actionMeta.removedValue.isFixed) {
          return;
        }
        break;
      case "clear":
        newValue = responseData.filter((v) => v.isFixed);
        break;
    }
    let val = newValue[newValue.length - 1]["value"];
    let requestData = new FormData();
    requestData.set("username", user["username"]);
    requestData.set("center", val);
    let response = await sendAddUserRequest(
      apiURLs["aarthi"] + "/add_user_new",
      requestData
    );
    if (response.status) {
      runAfterDataFetch();
    }
  };

  return (
    <Select
      value={value}
      isMulti
      styles={styles}
      options={responseData}
      onChange={onChange}
    />
  );
};
