const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    background: "black",
    height: "100vh",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  body: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    background: "black",
    width: "100%",
    overflow: "hidden",
    // paddingTop: '5%',
    // height: '100vh',
  },
  modelViewSelect: {
    width: "200px",
    height: "40px",
    marginBottom: "10px",
    marginTop: "2px",
    // background: '#3f51b5',
  },
  configsSelect: {
    width: "200px",
    marginLeft:"10px",
    height: "40px",
    background: "#fff",
    color: "#049c77",
  },
  saveButton: {
    width: "200px",
    height: "40px",
    background: "#3f51b5",
    color: "white",
    borderRadius: "5px",
    border: "none",
  },
  reportOpenButton: {
    width: "160px",
    height: "30px",
    background: "#049c77",
    color: "white",
    borderRadius: "5px",
    border: "none",
    marginBottom: "15px",
  },
  horseShoeButton: {
    width: "200px",
    height: "40px",
    background: "#049c77",
    color: "white",
    borderRadius: "5px",
    border: "none",
    marginTop: "15px",
  },
  imgWrapper: (fullscreen, maxWidth) => ({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: fullscreen ? "850px" : "49%",
    height: fullscreen ? "850px" : "49%",
  }),
  slider: {
    width: "80%",
  },
  optionsDrawer: (optionsOpen) => ({
    // display: 'flex',
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    // background: "#fff",
    background: "#090c29",
    height: "calc(100vh - 40px)",
    width: optionsOpen ? "300px" : "0px",
    padding: "20px 20px 0 20px",
    transition: "all 0.5s ease-in-out",
    opacity: optionsOpen ? "1" : "0",
    color: "#90cdf4",
    overflowY: "auto",
  }),
  stoneButton: {
    width: "70%",
    minWidth: "100px",
    height: "30px",
    margin: "5px",
    background: "#049c77",
  },
  addStoneButton: {
    width: "120px",
    height: "30px",
    margin: "0px",
    background: "#041c4a",
  },
  stoneButtonWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  selectedStone: {
    marginTop: "50px",
    width: "90%",
    height: "100px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "start",
  },
  selectedStoneTextWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  stoneTextHeader: {
    fontSize: "14pt",
    fontWeight: "bold",
  },
  probeText: {
    fontSize: "12pt",
    fontWeight: "bold",
    color: "black",
  },
  deleteStone: {
    height: "20px",
    color: "red",
    cursor: "pointer",
  },
  addStone: {
    height: "20px",
    color: "green",
    cursor: "pointer",
  },

  reportModal: (mode) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    border: "2px solid #000",
    borderRadius: "5px",
    width: mode === "view" ? "60%" : "80%",
    height: "90%",
    padding: "20px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // zIndex: '100',
    overflow: "auto",
    // display: 'none',
    opacity: 1,
  }),
  reportModalHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: "40px",
    width: "98%",
  },
  reportConfigModal: {
    background: "white",
    borderRadius: "5px",
    width: "500px",
    height: "300px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
  },
  fovModal: {
    background: "white",
    borderRadius: "5px",
    width: "500px",
    height: "300px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    justifyContent: "center",
  },
  reportTableHeaderItem: {
    textAlign: "center",
    verticalAlign: "middle",
    color: "#000",
  },
  reportTableBodyItem: {
    textAlign: "center",
    verticalAlign: "middle",
    maxHeight: "30px",
  },

  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    border: "2px solid #000",
    borderRadius: "5px",
    width: "50%",
    height: "50%",
    padding: "20px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // zIndex: '100',
    overflow: "auto",
    // display: 'none',
    opacity: 1,
  },
  modalHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  modalBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  modalBodyRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  modalBodyRowItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  modalBodyRowItemText: {
    fontSize: "16pt",
    fontWeight: 800,
    color: "black",
    margin: "5px",
  },
  wrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    // alignItems: 'center',
    background: "#4a4a4a",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  optionsWrapper: {
    minWidth: "200px",
    maxWidth: "500px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    zIndex: "1",
  },
  vtkContainer: (display, fullscreen) => ({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: display ? (fullscreen ? "1200px" : "49%") : "0px",
    height: display ? (fullscreen ? "1200px" : "49%") : "0px",
  }),
  segmentName: (color) => ({
    flex: 1,
    fontSize: "14pt",
    color: color,
    fontWeight: "bold",
  }),
  segmentNameWrapper: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  modelSelect: {
    background: "#fff",
    padding: "10px",
    margin: "10px",
  },
  visibilityEye: (color) => ({
    // paddingLeft: '5px',
    // paddingRight: '10px',
    fill: color,
    color: color,
  }),
  visibilityEyeWrapper: {
    height: "100%",
    // width: '100%',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  optionsButton: {
    width: "40px",
    height: "40px",
    padding: "10px",
    marginLeft: "20px",
  },
  tableItem: {
    // width: '100%',
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  saveModal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    border: "2px solid #000",
    borderRadius: "5px",
    width: "50%",
    maxWidth: "200px",
    height: "80px",
    padding: "20px",
    position: "absolute",
    top: "10%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // zIndex: '100',
    overflow: "auto",
    // display: 'none',
    opacity: 1,
  },
  errorText: {
    color: "red",
    fontSize: "10pt",
  },
  editInput: (width) => ({
    // width: '100%',
    width: width,
    height: "30px",
    border: "none",
    borderRadius: "1px",
    paddingLeft: "10px",
    fontSize: "12pt",
  }),
  editIcon: {
    marginLeft: "10px",
    cursor: "pointer",
    color: "#049c77",
  },
  container: {
    width: "80%",
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gridGap: "10px",
    placeItems: "center",
  },
  container2: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "10px",
    height: "100%",
    width: "1000px",
    overflowY: "scroll",
  },
  labelOption: (clickable) => ({
    display: "flex",
    flexDirection: "row",
    maxHeight: "20px",
    justifyContent: "space-between",
    margin: "3px",
    minWidth: "40px",
    width: "100%",
    padding: "3px",
    alignItems: "center",
    cursor: clickable ? "pointer" : "default",
  }),
  labelOptionText: (color) => {
    return {
      color: color,
      fontSize: "0.8em",
      paddingRight: "2px",
      flexGrow: 4,
    };
  },
  optionsGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
    placeItems: "center",
  },
  optionsDrawerContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "#90cdf4",
  },
  feedbackModal: {
    background: "white",
    borderRadius: "5px",
    width: "600px",
    height: "450px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    justifyContent: "space-between",
    alignItems: "center",
    overflowY:"scroll"
  },
  feedback: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderRadius: "10px",
    padding: "10px",
    background: "#eeffee",
    marginBottom: "2px",
    marginTop: "2px",
  },
  feedbackFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  select: {
    width: "400px",
    height: "40px",
    fontSize: "20px",
    fontWeight: "700",
    background: "#fff",
    color: "#000",
  },
};

export default styles;
